import React, { useState, useEffect, useRef } from 'react';
import { useAuth, getLoggedInUserData, getToken } from '../../context/AuthContext';
import useApi from '../../utility/apiCall';
import API_URL from '../../config/config';
import FlashMessage from '../../components/FlashMessage'; 

// Bootstrap File
import { Modal } from 'react-bootstrap'

// Images Common File
import * as Img from '../../components/Img'

// Icons File
import Icons from '../../components/icons'

// Design Files
import Breadcrumb from '../../components/Breadcrumb'
import BannerImg from "../../components/BannerImg";
import PaymentMethodCard from './PaymentMethodCard'
import Input from '../../components/CommonInput/Input'
import AccountDropItem from './AccountDropItem'

// AccountDropItem demo img
import walletIcon from "../../img/icons/paymentmethod/wallet.png";
import mt5 from "../../img/icons/paymentmethod/mt5.webp";

// Image
// import USDTTRC20 from "../../img/icons/paymentmethod/USDT-TRC20.png"
// import USDTBEP20 from "../../img/icons/paymentmethod/USDT-BEP20.png"
// import USDTERC20 from "../../img/icons/paymentmethod/USDT-ERC20.png"
// import bitcoin from "../../img/icons/paymentmethod/bitcoin.png"
// import banktransfer from "../../img/icons/paymentmethod/bank-transfer.png"

export default function Deposit({ wallet_id = null }) {
  const { apiCall } = useApi();
  const { state, dispatch } = useAuth();
  const token = state.token || '';
  const [wallet, setWallet] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const isInitialMount = useRef(true);


  const [selectedMt5Account, setSelectedMt5Account] = useState(null);
  const [toMt5Account, setToMt5Account] = useState(null);
  const [mt5Accounts, setMt5Accounts] = useState(null);

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [currentCurrencyCode, setCurrentCurrencyCode] = useState(null);
  const [currentCurrencyRate, setCurrentCurrencyRate] = useState(null);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [showSendBtn, setShowSendBtn] = useState(false);
  const [requestSubmitSuccess, setRequestSubmitSuccsess] = useState(false);
  const [requestSubmitError, setRequestSubmitError] = useState(false);
  const [requestSubmitErrorMsg, setRequestSubmitErrorMsg] = useState(false);
  const [bannerImg, setBannerImg] = useState(null);
  const [loading, setLoading] = useState(true);

  //deposite fields
  
  const [type, setType]= useState('mt5');
  const [transactionType, setTransactionType] = useState('crypto');
  const [transferType, setTransferType] = useState('deposit');
  const [currencyId, setCurrencyId] = useState(null);
  const [paymentMethodId, setPaymentMethodId] = useState(null);
  const [userWalletId, setUserWalletId] = useState(null);
  const [amount, setAmount] = useState(null);
  const [usdAmount, setUsdAmount] = useState('0.00');
  const [transactionId, setTransactionId] = useState(null);
  // const [mt5Number, setMt5Number] = useState(null);
  const [screenshot, setScreenshot] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);


  // useEffect(() => {
  //   if(wallet_id && wallet_id !== null){
  //     setType('mt5')
  //   }
  // }, []);

  useEffect(() => {
    const fetchData = async () => {
      const loggedInUser = getLoggedInUserData();
      const isUserLoggedIn = !!loggedInUser;
      
      if (isUserLoggedIn) {
        if (loggedInUser.id && token) {
          try {
            const wallet = await getUserWallet();
            const paymentMethods = await getPaymentMethods();
            // Assuming getUserWalletGroupWise returns the data needed for wallets and walletGroups
          } catch (error) {
            console.error('Failed to fetch wallet group data:', error);
          }
        }
      }
    };

    if (isInitialMount.current) {
      isInitialMount.current = false;
      fetchData();
      getAccounts();

    }
  }, [token]);

  const fetchBanner = async () => {
    try {
        const response = await apiCall(`${API_URL}get-deposit-banner`, {});
        


        if (response.data.success) {
            setBannerImg(response.data.data.image_url);
        } else {
            console.error("API Response error:", response.data.message || 'Unknown error');
        }
    } catch (error) {
        console.error("Error fetching banner:", error.message || error);
    } finally {
        setLoading(false);
    }
    };

  const getUserWallet = async () => {
    // Logic to get user wallet group-wise data
    try {
      const response = await apiCall(API_URL +'get-user-wallet', {
          user_id: state.userData.id,
          token: token,
          wallet_id:wallet_id
      });
      if (response.data.success == '0') {
        setWallet(response.data.data);
      }
    } catch (error) {
        console.error('Error fetching data:', error);
    }
  };
  useEffect(() => {
    if(wallet){
      setUserWalletId(wallet.id);
    }
  }, [wallet]);

  const getPaymentMethods = async () => {
    // Logic to get user wallet group-wise data
    try {
      const response = await apiCall(API_URL +'get-payment-methods', {
          user_id: state.userData.id,
          token: token,
          nopaginate:1
      });
      if (response.data.success == '0') {
        setPaymentMethods(response.data.data);
      }
    } catch (error) {
        console.error('Error fetching data:', error);
    }
  };

  const handlePaymentMethodIdChange = (payment_method) =>{
    setPaymentMethodId(payment_method);
  }
  
  useEffect(() => {
    setErrors({});
    setSelectedPaymentMethod(paymentMethods.find(method => method.id === paymentMethodId));
  }, [paymentMethodId]);

  useEffect(() => {

      fetchBanner();
    }, []);
  
  useEffect(() => {
    if(selectedPaymentMethod){
      handleStepTabClick('step2')
      setCurrencyId(selectedPaymentMethod?.currency?.id);
      setTransactionType(selectedPaymentMethod?.currency?.type);
      setCurrentCurrencyCode(selectedPaymentMethod?.currency?.code);
      setCurrentCurrencyRate(selectedPaymentMethod?.currency?.rate_usd)
    }
  }, [selectedPaymentMethod]);

  useEffect(() => {
    if(parseFloat(amount) > 0 && amount !== null && amount !== ''){
      if(currentCurrencyRate > 0){
        setUsdAmount(parseFloat(amount * currentCurrencyRate).toFixed(2));
      } else {
        setUsdAmount(parseFloat(amount / currentCurrencyRate).toFixed(2));
      }
    } else {
      setUsdAmount('0.00');
    }
  }, [amount]);

  useEffect(() => {
    if(requestSubmitSuccess){
      setTimeout(function(){
        handleStepTabClick('step1');
      },1000)
    }
  }, [requestSubmitSuccess]);

  const handleAmountChange = (value) => {
    if(value.length <= 10){      
      setAmount(value);
      setErrors((prev) => ({ ...prev, amount: '' })); 
    }
  };

  // const handleMt5NumberChange = (value) => {
  //   setMt5Number(value);
  //   setErrors((prev) => ({ ...prev, mt5Number: '' }));
  // };
  const handleTransactionIdChange = (value) => {
    setTransactionId(value);
    setErrors((prev) => ({ ...prev, transactionId: '' }));
  };
  

  const handleCopyAddress =()=>{
    navigator.clipboard.writeText(selectedPaymentMethod.deposit_address).then(() => {
      console.log('Deposit address copied to clipboard!');
      // Add user feedback here if desired
    }).catch(err => {
      console.error('Failed to copy the address: ', err);
    });
  }

  const handleCopyAccountNumber =()=>{
    navigator.clipboard.writeText(selectedPaymentMethod.account_number).then(() => {
      console.log('Deposit address copied to clipboard!');
      // Add user feedback here if desired
    }).catch(err => {
      console.error('Failed to copy the address: ', err);
    });
  }

  const handleCopyIfscCode =()=>{
    navigator.clipboard.writeText(selectedPaymentMethod.ifsc_code).then(() => {
      console.log('Deposit address copied to clipboard!');
      // Add user feedback here if desired
    }).catch(err => {
      console.error('Failed to copy the address: ', err);
    });
  }

  const handleCopyUpiId =()=>{
    navigator.clipboard.writeText(selectedPaymentMethod.upi_id).then(() => {
      console.log('Deposit address copied to clipboard!');
      // Add user feedback here if desired
    }).catch(err => {
      console.error('Failed to copy the address: ', err);
    });
  }

  
  

  // --- Payment section Tabbing Js Start
  const [stepTab, setStepTab] = useState('step1');

  const handleStepTabClick = (stepTabId) => {
    if(stepTabId == 'step1'){
      setPaymentMethodId(null);
      setPaymentTab('QRCode');
    }
    setStepTab(stepTabId);
    document.getElementsByClassName('account-content-area')[0].scrollTo(0, 0);
  };
  // --- Payment section Tabbing Js End


  // --- Payment section Tabbing Js Start
  const [paymentTab, setPaymentTab] = useState('QRCode');

  const handlePaymentClick = (PaymentTabId) => {
    setPaymentTab(PaymentTabId);
  };
  // --- Payment section Tabbing Js End


  // --- Payment QR Code Info Modal Js Start
  const [infoShow, setInfoShow] = useState(false);
  const handleInfoClose = () => {
    setInfoShow(false);
  };
  const handleInfoShow = () => {
    setInfoShow(true);
  }
  // --- Payment QR Code Info Modal Js End
  const getAccounts = async() => {
    try {
      const response = await apiCall(API_URL +'get-user-all-wallets', {
          user_id: state.userData.id,
          token: token,
          mt5_not_type:'both'

      });
      if (response.data.success == '0') {
        setMt5Accounts(response.data.data);
        if(wallet_id && wallet_id !== null){
          setToMt5Account(wallet_id);
        }
      }
    } catch (error) {
        console.error('Error fetching data:', error);
    }
  }

  const handleMt5AccountChange = (account) => {
    setToMt5Account(account);
    setErrors((prev) => ({ ...prev, toMt5Account: '' }));
  }

  useEffect(() => {
    if(toMt5Account !== null){
      setSelectedMt5Account(mt5Accounts.find(wallet => wallet.id === toMt5Account));
    }
  }, [toMt5Account]);

  // --- 
  const demoonChange = () => {

  };
  // --- 


  // --- ---
  const [selectedFiles, setSelectedFiles] = useState([]);

  // const handleFileSelect = (e) => {
  //   const files = Array.from(e.target.files);
  //   setScreenshot(files[0]);
  //   setErrors((prev) => ({ ...prev, selectedFiles: '' }));
  //   setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
  // };

  const handleFileSelect = (e) => {
    const files = Array.from(e.target.files);

    const validImageTypes = ['image/jpeg','image/jpg', 'image/png'];
    const validFiles = files.filter(file => validImageTypes.includes(file.type));
    const invalidFiles = files.filter(file => !validImageTypes.includes(file.type));
    
    if (invalidFiles.length > 0) {
        alert("Only JPG, JPEG, and PNG files are allowed.");
        return;
    }

    // Limit to 5 files
    if (files.length > 5) {
      alert("You can only upload a maximum of 5 files.");
      return;
    }
    
    // Combine new files with existing selected files
    setSelectedFiles((prevFiles) => {
      const combinedFiles = [...prevFiles, ...files];
      
      // Ensure combinedFiles does not exceed 5 files
      if (combinedFiles.length > 5) {
        alert("You can only have a maximum of 5 files.");
        return prevFiles; // Return previous state if limit exceeded
      }
      return combinedFiles;
    });
    // setScreenshot(files);
    
    setErrors((prev) => ({ ...prev, selectedFiles: '' }));
  };

  // const handleDragOver = (e) => {
  //   e.preventDefault();
  //   e.target.classList.add('dragover');
  // };

  // const handleDragLeave = (e) => {
  //   e.preventDefault();
  //   e.target.classList.remove('dragover');
  // };

  // const handleDrop = (e) => {
  //   e.preventDefault();
  //   const files = Array.from(e.dataTransfer.files);
  //   setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
  //   e.target.classList.remove('dragover');
  // };
  const handleDragOver = (e) => {
    e.preventDefault();
    e.target.classList.add('dragover');
  };
  
  const handleDragLeave = (e) => {
    e.preventDefault();
    e.target.classList.remove('dragover');
  };
  
  const handleDrop = (e) => {
    e.preventDefault();
    e.target.classList.remove('dragover');
  
    const files = Array.from(e.dataTransfer.files);
    const validImageTypes = ['image/jpeg','image/jpg', 'image/png'];
    const validFiles = files.filter(file => validImageTypes.includes(file.type));
    const invalidFiles = files.filter(file => !validImageTypes.includes(file.type));
    
    if (invalidFiles.length > 0) {
        alert("Only JPG, JPEG, and PNG files are allowed.");
        return;
    }

    // Limit to 5 files
    if (files.length > 5) {
      alert("You can only upload a maximum of 5 files.");
      return;
    }
    // Limit to 5 files
    setSelectedFiles((prevFiles) => {
      const combinedFiles = [...prevFiles, ...files];
  
      // Ensure combinedFiles does not exceed 5 files
      if (combinedFiles.length > 5) {
        alert("You can only have a maximum of 5 files.");
        return prevFiles; // Return previous state if limit exceeded
      }
  
      // Set the screenshot with all selected files
      // setScreenshot(combinedFiles); // Store all files (existing + new)
      return combinedFiles;
    });
  
    setErrors((prev) => ({ ...prev, selectedFiles: '' }));
  };
  

  const preventDefault = (e) => {
    e.preventDefault();
  };

  // const deleteFile = (index) => {
  //   const updatedFiles = [...selectedFiles];
  //   updatedFiles.splice(index, 1);
  //   setSelectedFiles(updatedFiles);
  // };

  const deleteFile = (index) => {
    setSelectedFiles((prevFiles) => {
      const updatedFiles = [...prevFiles];
      updatedFiles.splice(index, 1);
  
      // Update the screenshot state to reflect the current selected files
      // setScreenshot(updatedFiles);
  
      return updatedFiles;
    });
  };
  
  function formatFileSize(size) {
    const KB = 1024;
    const MB = KB * KB;
    const GB = MB * KB;
  
    if (size < KB) {
      return `${size} bytes`;
    } else if (size < MB) {
      return `${(size / KB).toFixed(2)} KB`;
    } else if (size < GB) {
      return `${(size / MB).toFixed(2)} MB`;
    } else {
      return `${(size / GB).toFixed(2)} GB`;
    }
  }

  function getFileExtension(fileName) {
    return fileName.split('.').pop();
  }

  const validateFields = () => {
    const newErrors = {};
    // alert(amount +"---" +selectedPaymentMethod.lower_limit)
    if (!amount) {
      newErrors.amount = 'Amount is required';
    } else if (isNaN(amount) || amount <= 0) {
      newErrors.amount = 'Amount must be a positive number';
    } else if (parseFloat(amount) < parseFloat(selectedPaymentMethod.lower_limit)) {
      newErrors.amount = `Amount must be ${selectedPaymentMethod.lower_limit} or greater`;
    } else if (amount.toString().length > 10) {
      newErrors.amount = 'Amount must be up to 10 digits';
    }

    if (!toMt5Account) {
      newErrors.toMt5Account = 'MT5 Number is required';
    } 
    // if (!mt5Number && transactionType === 'crypto') {
    //   newErrors.mt5Number = 'MT5 Number is required';
    // } else if (isNaN(mt5Number) || mt5Number <= 0 && transactionType === 'crypto') {
    //   newErrors.mt5Number = 'MT5 Number must be a positive number';
    // }
    if (!transactionId && transactionType === 'crypto')  {
      newErrors.transactionId = 'TXID is required';
    }
    
    if (!transactionId && transactionType === 'bank')  {
      newErrors.transactionId = 'Rfrnce/ UTR Number is required';
    }
    if (selectedFiles.length == 0)  {
      newErrors.selectedFiles = 'Screenshot is required';
    }
    return newErrors;
  };
  // ---
  const handleSubmit = async() => {
    const valErrors = validateFields();
    console.log(valErrors)

    if (isSubmitting) return; // Check if already submitting
    setIsSubmitting(true);

    if (Object.keys(valErrors).length === 0) {
      // Create a FormData object
      const formData = new FormData();

      selectedFiles.forEach((file) => {
        formData.append('screenshot[]', file);
      });
      formData.append('user_id',state.userData?.id);
      formData.append('token',state?.token);
      formData.append('type',type);
      formData.append('transaction_type',transactionType);
      formData.append('transfer_type',transferType);
      
      formData.append('payment_method_id',paymentMethodId);
      formData.append('user_wallet_id',toMt5Account);
      formData.append('currency_id',currencyId);
      formData.append('amount',amount);
      formData.append('mt5',toMt5Account);      
      formData.append('transaction_id',transactionId);
      // formData.append('screenshot',screenshot);
      
      // Append each file to FormData
      // selectedDocumentTypes.forEach((value, index) => {
      //   if(selectedFiles[index].length > 0){
      //     formData.append(`document_type_id[]`, value);
      //   }
      // });
      // selectedFiles.forEach((object, index) => {
      //   object.forEach((file, idx) => {
      //     formData.append(`documents[]`, file);
      //   });
      // });

      try {
        // Send FormData using Axios
        setIsLoading(true);
        setRequestSubmitSuccsess(false)
        setRequestSubmitError(false)
        apiCall(API_URL + 'send-transaction-request', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(response => {
            if(response.data.success == '0'){
              setTransactionType('crypto')
              setPaymentMethodId(null)
              setUsdAmount('')
              setAmount('');
              // setMt5Number(null);
              setTransactionId('');
              setScreenshot(null);
              setSelectedFiles([]);
              setRequestSubmitSuccsess(true)
              
            } else {
              setRequestSubmitError(true)
              setRequestSubmitErrorMsg(response.data.data.error)
            }
            setIsLoading(false);
        })
        .catch(error => {
          setIsLoading(false);
          setRequestSubmitError(true)
          setRequestSubmitErrorMsg(error.response.data.message)
          //console.error('Error uploading profile picture:', error);
        });
      } catch (error) {
        //console.error('Error uploading files:', error);
      }
    } else {
      // Set errors to state
      setErrors(valErrors);
      setIsSubmitting(false);
    }
  };

  return (
    <>


    {stepTab === 'step1' ? ( 
    <Breadcrumb breadcrumbIcon="DepositSvg" breadcrumbHeading="Deposit" />
    ) : stepTab === 'step2' ? (
      <Breadcrumb onClick={() => handleStepTabClick('step1')} backBtn="bmb-back-btn" breadcrumbIcon="DepositSvg" breadcrumbHeading="Deposit" />
    ) : stepTab === 'step3' ? (
      <Breadcrumb onClick={() => handleStepTabClick('step2')} backBtn="bmb-back-btn" breadcrumbIcon="DepositSvg" breadcrumbHeading="Deposit" />
    ) : null }

    <div className="container-lg pt-3 pb-5 px-lg-4">
      
      <div className="row row-gap-4">

       <div className="col-12">
          {loading ? (
            <div className="skeletant-bx skeletant-design sh-95"></div>
          ) : (
            bannerImg ? (
              <BannerImg src={bannerImg} />
            ) : ''
          )}
        </div>

        {/* --- Payment Methods Section Step 1 Start --- */}
        <div className={`col-lg-12 ${stepTab === 'step1' ? '' : 'd-none'} `} id="step1">
          <div className="common-box cb-p-bg-none">
              <div className="common-box-heading">All payment methods</div>
              <div className="row row-gap-4">

              {paymentMethods.length == 0 && 
                <>
                {/* --- Skeletant Bx Start --- */}
                <div className="col-sm-6">
                    <div className="skeletant-bx skeletant-design sh-171">
                    </div>
                </div>

                <div className="col-sm-6">
                    <div className="skeletant-bx skeletant-design sh-171">
                    </div>
                </div>

                <div className="col-sm-6">
                    <div className="skeletant-bx skeletant-design sh-171">
                    </div>
                </div>

                <div className="col-sm-6">
                    <div className="skeletant-bx skeletant-design sh-171">
                    </div>
                </div>

                <div className="col-sm-6">
                    <div className="skeletant-bx skeletant-design sh-171">
                    </div>
                </div>

                <div className="col-sm-6">
                    <div className="skeletant-bx skeletant-design sh-171">
                    </div>
                </div>
                {/* --- Skeletant Bx End --- */}
                </>
              }

              {paymentMethods.map((item, index) => (
                <PaymentMethodCard
                  key={index}
                  imgSrc={item.image_url}
                  label={item.name}
                  isUnavailable={item.status === 'active' ? false : true}
                  processingTime={item.name == 'Bank Transfer' ? `${item.processing_time}`:`Instant - ${item.processing_time}`}
                  fee={`${item.fee} %`}
                  limits={`Minimum Deposit: ${item.lower_limit ? `${item.lower_limit} ${item.currency ? item.currency.code : ''}` : ''}`}

                  //onClick={() => handleStepTabClick('step2')}
                  onClick={() =>handlePaymentMethodIdChange(item.id)}
                />
              ))}

                {/* <PaymentMethodCard
                  imgSrc={USDTERC20}
                  label="USDT ERC20"
                  isUnavailable={true}
                  processingTime="Instant - 30 minutes"
                  fee="0%"
                  limits="10 - 1,000 USD"
                  onClick={() => handleStepTabClick('step2')}
                /> */}

              </div>
          </div>
        </div>
        {/* --- Payment Methods Section Step 1 End --- */}


        {/* --- Payment Details Section Step 2 Start --- */}
        <div className={`col-xl-8 col-12 mx-auto ${stepTab === 'step2' ? '' : 'd-none'} `} id="step2">

          <div className="common-box mb-4">
            <div className="common-box-heading">Selected payment method</div>
            {selectedPaymentMethod &&
            <div className="seleted-payment-methods">
              <img className="spmc-img" src={selectedPaymentMethod.image_url} alt="" />
              <div className="spmc-label">{selectedPaymentMethod.name}</div>
            </div>
            }
          </div>


          <div className="common-box">
            <div className="common-box-heading">Payment Details</div>
            <div className={`common-box-info ${paymentTab === 'QRCode' ? '' : 'd-none'}`} onClick={handleInfoShow} ><Icons.InfoSvg /></div>

            {selectedPaymentMethod && selectedPaymentMethod.currency.type == 'bank' &&
            <div className="payment-tab-bx">
              <div className={`payment-tab-item  ${paymentTab === 'QRCode' ? 'active' : ''}`} onClick={() => handlePaymentClick('QRCode')}>
                  <img className="payment-img" src={Img.qricon} alt="" />
                  Scan <br /> QR
              </div>

              {selectedPaymentMethod && selectedPaymentMethod.currency.type == 'bank' && <div className={`payment-tab-item  ${paymentTab === 'BankTransfer' ? 'active' : ''}`} onClick={() => handlePaymentClick('BankTransfer')}>
                  <img className="payment-img" src={Img.bankicon} alt="" /> 
                  Bank <br/> Detail
              </div>}

              {selectedPaymentMethod && selectedPaymentMethod.currency.type == 'crypto' && <div className={`payment-tab-item  ${paymentTab === 'otherdeposit' ? 'active' : ''}`} onClick={() => handlePaymentClick('otherdeposit')}>
                  <img className="payment-img" src={Img.bankicon} alt="" /> 
                  Deposit <br/> Address
              </div>}
            </div>
            }

            {selectedPaymentMethod && selectedPaymentMethod.currency.type == 'bank' &&
            <div className="payment-data-bx mb-3">
              <div className="pdb-item upi-item">
                <div className="pdb-i-lable">UPI ID</div>
                <div className="pdb-i-data pid-style-2">{selectedPaymentMethod?.upi_id}
                  <span className="pdb-i-icon" title="UPI ID" onClick={handleCopyUpiId}><Icons.CopySvg/></span> </div>
              </div>
            </div>
            }
            {selectedPaymentMethod && selectedPaymentMethod.currency.type == 'bank' &&
            <>
            <div className={`payment-data-bx pdb-style-2 ${paymentTab === 'BankTransfer' ? '' : 'd-none'} `} id="BankTransfer">

              <div className="pdb-item">
                <div className="pdb-i-lable">Account Name:</div>
                <div className="pdb-i-data">{selectedPaymentMethod?.account_name}</div>
              </div>

              <div className="pdb-item">
                <div className="pdb-i-lable">Bank Name:</div>
                <div className="pdb-i-data">{selectedPaymentMethod?.bank_name}</div>
              </div>

              <div className="pdb-item">
                <div className="pdb-i-lable">Branch:</div>
                <div className="pdb-i-data">{selectedPaymentMethod?.branch}</div>
              </div>

              <div className="pdb-item">
                <div className="pdb-i-lable">Account Number:</div>
                <div className="pdb-i-data pid-style-2">{selectedPaymentMethod?.account_number}
                  <span className="pdb-i-icon" title="Copy account number" onClick={handleCopyAccountNumber}><Icons.CopySvg/></span> </div>
              </div>

              <div className="pdb-item">
                <div className="pdb-i-lable">IFSC Code:</div>
                <div className="pdb-i-data pid-style-2">{selectedPaymentMethod?.ifsc_code}
                  <span className="pdb-i-icon" title="Copy IFSC code" onClick={handleCopyIfscCode}><Icons.CopySvg/></span> </div>
              </div>
            </div>

            <div className={`payment-data-bx pdb-style-3 ${paymentTab === 'QRCode' ? '' : 'd-none'} `} id="QRCode">

              <div className="qr-code-box">
                <img src={selectedPaymentMethod?.qr_code_image_url} alt=''/>
              </div>
              
            </div>
            </>
            }
            {selectedPaymentMethod && selectedPaymentMethod.currency.type == 'crypto' &&
            <div className={`payment-data-bx pdb-style-2`} id="otherdeposit">
                
                <div className="pdb-item">
                  <div className="pdb-i-lable">{currentCurrencyCode}</div>
                  <div className="pdb-i-data">{selectedPaymentMethod?.name}</div>
                </div>

                <div className="pdb-item">
                  <div className="pdb-i-lable">Deposit Address:</div>
                  <div className="pdb-i-data pid-style-2 common-word-wrap">{selectedPaymentMethod?.deposit_address}
                    <span className="pdb-i-icon"  onClick={handleCopyAddress} title="Copy deposit address"><Icons.CopySvg/></span> 
                  </div>
                </div>
                <div className={`payment-data-bx pdb-style-3`} id="QRCode">
                  <div className="qr-code-box">
                    <img src={selectedPaymentMethod?.qr_code_image_url} alt=''/>
                  </div>
                </div>
                <div className="pdb-item">
                  <div className="pdb-i-lable">Procedure:</div>
                  <div className="pdb-i-data">1 - Complete the trasnfer to the USDT TRC20 deposit address.</div>
                  <div className="pdb-i-data">2 - Once you get the confirmation, then enter the amount transferred along with the Proof of Transfer. - <strong>Proceed</strong> </div>
                  <div className="pdb-i-data">3 - We will verify your transaction and automatically approve your Deposit.</div>
                </div>
            </div>
            }

            <div className="input-note-2 mt-4 mb-2"><Icons.InfoSvg /> Point your smartphone’s camera at the QR code provided by the deposit service. Ensure the QR code is within the frame and clearly visible.</div>
            
            <div className="payment-data-bx pdb-style-2 pdb-padding">
              <div className="pdb-item minimum-pdb-item">
                  <div className="pdb-i-lable">Minimum Deposit:</div>
                  <div className="pdb-i-data">{selectedPaymentMethod ? selectedPaymentMethod.lower_limit : ""}<span className="pdb-currency">{currentCurrencyCode}</span></div>
                </div>
            </div>
          </div>

          {/* <div className='d-flex flex-wrap justify-content-between gap-2 mt-4 px-3'>
              <div className="common-btn-item cbi-fill" onClick={() => handleStepTabClick('step1')}><span>Previous</span></div>
              <div className="common-btn-item cbi-fill" onClick={() => handleStepTabClick('step3')}><span>Next</span></div>
          </div> */}

          <div className="mt-4">
            <div className="common-submit-btn" onClick={() => handleStepTabClick('step3')}>Continue</div>
          </div>
        </div>
        {/* --- Payment Details Section Step 2 End --- */}



        {/* --- Payment Form Section Step 3 Start --- */}
        <div
          className={`col-xl-8 col-12 mx-auto ${stepTab === 'step3' ? '' : 'd-none'}`}
          id="step3"
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault(); // Prevent default behavior
              handleSubmit(); // Call your submit handler
            }
          }}
          tabIndex={0} // Make the div focusable
        >

          <div className="common-box">

            <div className="common-box-heading">Fill your payment details</div>
            {/* <div className="common-box-sub-heading mb-4">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</div> */}

            <div className="payment-data-bx pdb-style-2 pdb-padding mb-3">
              <div className="pdb-item minimum-pdb-item">
                  <div className="pdb-i-lable">Minimum Deposit:</div>
                  <div className="pdb-i-data">{selectedPaymentMethod ? selectedPaymentMethod.lower_limit : ""}<span className="pdb-currency">{selectedPaymentMethod ? currentCurrencyCode ? currentCurrencyCode : "" : ""}</span></div>
                </div>
            </div>

            <div className="row signinup-group-style sg-fem-style">

            <div className="col-sm-6">
              <AccountDropItem
                    className={errors.toMt5Account ? "error-group" : ""}
                    label="MT5 Accounts"
                    img={mt5}
                    heading={`${selectedMt5Account ? selectedMt5Account.account == 'mt5' ? selectedMt5Account.account_number: 'Wallet' : "Select Account" }`}
                    options={mt5Accounts}
                    onChange={handleMt5AccountChange}
                    selected={selectedMt5Account}
                />
                {errors.toMt5Account && <span className="form-error-msg">{errors.toMt5Account}</span>}
              </div>

              <div className="col-sm-6">
                <Input heading="Amount" 
                className={`sgl-icon ${errors.amount ? "error-group" : ""}`}
                type="number" sgl={currentCurrencyCode} placeholder="Enter amount" value={amount} onChange={handleAmountChange}/>
                {errors.amount && <span className="form-error-msg">{errors.amount}</span>}
                {/* <div className="conversion-rate-bx crb-style-2">
                  <div className="current-rate">minimum Deposit :{selectedPaymentMethod ? selectedPaymentMethod.lower_limit+" "+ currentCurrencyCode : ""}</div>
                </div> */}
              </div>

              <div className="col-sm-6">
                <div className="conversion-rate-bx crb-style-2">
                    <div className="current-rate">1 {currentCurrencyCode} =  {currentCurrencyRate} USD</div>
                    {/* <div className="current-amount">To be Deposit:<span>{usdAmount}USD</span></div> */}
                    <div className="current-amount">USD<span>{usdAmount}</span></div>
                </div>
              </div>
              
                {/* <div className={`col-sm-6 ${transactionType !== 'crypto' ? 'd-none':''}`}>
                <Input heading="MT5 Number" type="number" placeholder="Enter MT5 Number" value={mt5Number} onChange={handleMt5NumberChange} />
                {errors.mt5Number && <span className="form-error-msg">{errors.mt5Number}</span>}
              </div>  */}
              
              {(transactionType == 'bank') &&
              
                <div className="col-sm-6">
                  <Input className={errors.transactionId ? "error-group" : ""} heading="Rfrnce/ UTR Number" type="text" placeholder="Enter Rfrnce/ UTR Number" value={transactionId} onChange={handleTransactionIdChange}/>
                  {errors.transactionId && <span className="form-error-msg">{errors.transactionId}</span>}
                </div>
              }
              {(transactionType != 'bank') &&
              
                <div className="col-sm-6">
                  <Input className={errors.transactionId ? "error-group" : ""} heading="TXID" type="text" placeholder="Enter TXID" value={transactionId} onChange={handleTransactionIdChange}/>
                  {errors.transactionId && <span className="form-error-msg">{errors.transactionId}</span>}
                </div>
              }
              
              
            </div>

            <div className="sgr-outer-label mb-2">Payment Screenshot</div>
            <div className="row row-gap-3">
                  <div className="col-12">
                    <label htmlFor="IdProoffileupload" className={`fileupload ${errors.selectedFiles ? "error-group" : ""}`} onDrop={handleDrop} onDragOver={handleDragOver}  onDragLeave={handleDragLeave}  onMouseOut={handleDragLeave}>
                        <Icons.UploadSvg />
                        <div className="feud-lable">Drag and drop your screenshot(s) or <span>Browse</span></div>
                        <div className="feud-sub-lable">JPEG, PNG, JPG  · 3MB per file</div>
                    </label>
                    <input hidden type="file" accept="image/jpeg, image/png, image/jpg" name="IdProoffileupload[]" id="IdProoffileupload"  onChange={handleFileSelect} multiple/>
                  </div>
                {errors.selectedFiles && <span className="form-error-msg mt-0">{errors.selectedFiles}</span>}

                {selectedFiles.map((file, index) => (
                  <>
                    <div className="col-lg-6 col-12" key={index}>
                      <div className="upload-document-item">
                          <div className="udi-img-bx">
                              <img src={URL.createObjectURL(file)} alt="" />
                          </div>
                          <div className="udi-data-bx">
                              <div className="udi-data-name">{file.name}</div>
                              <div className="udi-data-type-size">{getFileExtension(file.name)}, {formatFileSize(file.size)}</div>
                          </div>
                          <div className="udi-icon-bx udi-delete" onClick={() => deleteFile(index)}>
                              <Icons.TrashSvg />
                          </div>
                      </div>
                    </div>
                  </>
                ))}
            </div>

          </div>

          <div className="mt-4">
            {/* <div className="common-submit-btn">Send Deposit Request</div> */}
            {!isLoading && <div className="common-submit-btn" onClick={handleSubmit}>Deposit</div>
            }

            {isLoading && <div className="lsb-loader-btn">
              <img src={Img.loading} alt="" />Please wait ....
            </div>
            }
            {requestSubmitSuccess && <FlashMessage type="success" isvisible={true} message="Deposite request submitted successfully" />}
            {requestSubmitError && <FlashMessage type="error" isvisible={true} message={requestSubmitErrorMsg} />}
          </div>

        </div>
        {/* --- Payment Form Section Step 3 End --- */}

      </div>

    </div>

    {/* --- QR Code Info Modal Start --- */}
    <Modal className="zoom custom-content" centered show={infoShow} onHide={handleInfoClose}>
        <div className="custom-modal-header">
            <div className="cmh-lable">Notice</div>
            <span className="close-icon" onClick={handleInfoClose}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M368 368L144 144M368 144L144 368"></path>
                </svg>
            </span>
        </div>

        <div className="custom-modal-body">
          <div className="legal-content">
              <div className="legal-heading">How to scan QR code via mobile phone</div>
              <ul>
                  <li>Take a screenshot* of the QR code.</li>
                  <li>Open your UPI app, select the QR code scanner and scan it from image.</li>
                  <li>You’ll be able to browse through your files to find the screenshot with the QR code.</li>
                  <li>Confirm the payment and you’re done!</li>
              </ul>
          </div>
          <div className="legal-content">
              <div className="legal-heading">How to scan QR code via desktop</div>
              <ul>
                  <li>Open your UPI app on mobile phone, select the QR code scanner.</li>
                  <li>Hold your phone over the QR code on desktop display so that it’s clearly visible within the scan area.</li>
                  <li>Confirm the payment and you’re done!</li>
              </ul>
          </div>
        </div>
    </Modal>
    {/* --- QR Code Info Modal End --- */}

    </>
  )
}
