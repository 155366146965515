import React, { useState } from 'react';
import {Link} from "react-router-dom";
import { Helmet } from "react-helmet-async";

// Design Files
import { ThemeMode } from '../../context/ThemeMode';
import PageHeader from './PageHeader';
import PageBackBtn from './PageBackBtn';

// Images Common File
// import * as Img from '../../components/Img';

const hidepassSvg = () => {
  return(
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
    <path strokeLinecap="round" strokeLinejoin="round" d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88" />
  </svg>
  )
}

const viewpassSvg = () => {
  return(
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
    <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
    <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
  </svg>
  )
}


export default function PagePassword() {
  // --- ---
  const { isDarkMode } = ThemeMode();
  // --- ---
  // View passwords code
  const [showPasswords, setShowPasswords] = useState([false, false, false]);

  const togglePasswordVisibility = (index) => {
      const updatedShowPasswords = [...showPasswords];
      updatedShowPasswords[index] = !updatedShowPasswords[index];
      setShowPasswords(updatedShowPasswords);
  };

  return (
    <>
      <Helmet>
        <title>Reset a Password | Winpro Fx</title>
      </Helmet>

      <PageHeader />
      <PageBackBtn to="/register2" />

      <section className="lgh-wraper">
        <div className="container-lg cl-custome">
          <div className={`signinup-screen ${isDarkMode ? " " : "signinup-group-style"}`}>
            
            <div className="signinup-header">
              <div className="screen-hed">Set a password</div>
            </div>

            <form className="signinup-width">
              <div className="row">

                {[0, 1].map((index) => (
                  <div className={`col-12`} key={index}>
                      <div className="signinup-group sgr-icon">
                          <input type={showPasswords[index] ? 'text' : 'password'} placeholder={`${index === 0 ? "" : "Confirm "}Password`}/>
                          {/* <div className="group__label">{`${index === 0 ? "" : "Confirm"}`} Password<span>*</span></div> */}
                          {/* <div className="group__line"></div> */}
                          <span className={`group_right_icon ${showPasswords[index] ? "active" : "" }`} onClick={() => togglePasswordVisibility(index)} >
                              {showPasswords[index] ? viewpassSvg() : hidepassSvg()}
                          </span>
                          {/* <div className="input-note">{`${index === 0 ? "Password. Min 6 symbols, at least 1 lowercase letter, at least 1 upper-case, at least 1 non-letter" : ""}`}</div> */}
                      </div>
                  </div>
                ))}

                <div className="col-12">
                  <Link to="/verification" className="common-submit-btn">Continue</Link>
                  {/* <button type="button" className="common-submit-btn">Continue</button> */}

                  {/* <div className="lsb-loader-btn">
                      <img src={Img.loading} alt="" />Please wait ....
                  </div> */}
                </div>

                <div className="col-12 BackBtnIsShow">
                  <div className="signinup-link-outer-bx slob-fdc fdc-two mb-0">
                    Back to 
                    <Link to="/register2" className="signinup-link-two">Previous</Link>
                  </div>
                </div>

              </div>
            </form>

          </div>  
        </div>
      </section>      
      
    </>
  );
};
