import { React, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import FlashMessage from '../../components/FlashMessage';
import useApi from '../../utility/apiCall';
import API_URL from '../../config/config';
// Design Files
import PageHeader from './PageHeader';
import PageBackBtn from './PageBackBtn';
import { ThemeMode } from '../../context/ThemeMode';

// Images Common File
import * as Img from '../../components/Img';

export default function PageVerification() {
      // --- ---
      const { isDarkMode } = ThemeMode();
      // --- ---
  const { apiCall } = useApi();
  const navigate = useNavigate();
  const location = useLocation();
  const { email = '', phoneNumber = '', token = '', userId = '' } = location.state || {};

  const [newEmail, setNewEmail] = useState(email);
  const [newphoneNumber, setNewPhoneNumber] = useState(phoneNumber);

  const [selectedOption, setSelectedOption] = useState('');

  const [noSelectionError, setNoSelectionError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [emailValidationError, setEmailValidationError] = useState(false);
  const [phoneNumberError, setPhoneNumberError] = useState(false);

  const [verificationSuccsess, setVerificationSuccsess] = useState(false);
  const [verificationError, setVerificationError] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!email || !phoneNumber || !token) {
      navigate('/register')
    }
  }, [email, phoneNumber, token]);

  const handleOptionChange = (option) => {
    setSelectedOption(option);
  };

  const sendVerifyOtp = async () => {
    if (selectedOption == 'phone') {
      var data = newphoneNumber;
    } else {
      var data = newEmail;
    }
    try {
      setIsLoading(true);
      const response = await apiCall(API_URL +'send-verify-otp', {
        token: token,
        user_id: userId,
        type: selectedOption,
        email_phone: data,
      });
      if (response.data.success == '0') {
        if (selectedOption == 'phone') {
          var user_otp = response.data.data.phone_otp
        } else {
          var user_otp = response.data.data.email_otp
        }
        setTimeout(() => {
          navigate('/restoreotp', { state: {userId: response.data.data.id, userEmail: response.data.data.email,email_phone:data,token: token, type: selectedOption,resend_type:"verification" } });
        }, 2000);
        setVerificationSuccsess(true);
      } else if (response.data.success == '2') {
        setVerificationError(true);
      }
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }

  };




  const handleClick = () => {
    if (selectedOption) {
      if (newEmail || phoneNumber) {
        sendVerifyOtp();
      } else {
        if (!newphoneNumber) {
          setPhoneNumberError(true);
        }
        if (!newEmail) {
          setEmailError(true);
        }
      }

    } else {
      setNoSelectionError(true);
    }

  };


  useEffect(() => {
    if (noSelectionError) {
      setTimeout(() => {
        setNoSelectionError(false);
      }, 2000);
    }
  }, [noSelectionError]);

  const handlePhoneNumberChange = (e) => {
    setNewPhoneNumber(e.target.value);
    setPhoneNumberError(false);
  };


  const handleEmailChange = (e) => {
    const inputEmail = e.target.value;
    setNewEmail(inputEmail);
    setEmailError(false);

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (inputEmail && !emailRegex.test(inputEmail)) {
      setEmailValidationError(true);
    } else {
      setEmailValidationError(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>Register | Winpro Fx</title>
      </Helmet>

      <PageHeader />
      <PageBackBtn />

      <section className="lgh-wraper">
        <div className="container-lg cl-custome">
          <div className={`signinup-screen ${isDarkMode ? " " : "signinup-group-style"}`}>

            <div className="signinup-header">
              <div className="screen-hed">Let’s Confirm it’s really you</div>
              {/* <div className="screen-pera"></div> */}
            </div>

            <form className="signinup-width">
              <div className="row">

                <div className="col-12">
                  <div className="signinup-group-checkmark sgc-radio">
                    <input className="d-none" type="radio" checked={selectedOption === 'phone'} onChange={() => handleOptionChange('phone')} name="option" id="option1" />
                    <label className="checkmark-lable" htmlFor="option1"><span className="checkmark-icon"><div className="cmi-inner"></div></span> Get the code by text massage</label>
                  </div>
                </div>
                <div className="col-12">
                  {/* error-group */}
                  <div className="signinup-group">
                    <input type="text" value={newphoneNumber} disabled onChange={handlePhoneNumberChange} placeholder="Phone Number" />
                    {/* <div className="group__label">Phone Number</div> */}
                    {/* <div className="group__line"></div> */}
                    {phoneNumberError && <span className="form-error-msg">Phone number is required</span>}
                  </div>
                </div>

                <div className="col-12">
                  <div className="signinup-group-checkmark sgc-radio">
                    <input className="d-none" checked={selectedOption === 'email'} onChange={() => handleOptionChange('email')} type="radio" name="option" id="option2" />
                    <label className="checkmark-lable" htmlFor="option2"><span className="checkmark-icon"><div className="cmi-inner"></div></span>Get the code by email at</label>
                  </div>
                </div>
                <div className="col-12">
                  {/* error-group */}
                  <div className="signinup-group">
                    <input type="email" value={newEmail} disabled onChange={handleEmailChange} placeholder="Enter Email" />
                    {/* <div className="group__label">Enter Email</div> */}
                    {/* <div className="group__line"></div> */}
                    {emailError && <span className="form-error-msg">Email is required</span>}
                    {emailValidationError && <span className="form-error-msg">Invalid email format</span>}
                  </div>
                </div>

                {verificationSuccsess && <FlashMessage type="success" isvisible={true} message="Otp sent" />}
                {verificationError && <FlashMessage type="error" isvisible={true} message="Something went wrong." />}

                <div className="col-12">
                  {/* to="/restoreotp" */}
                  {!isLoading && <div onClick={handleClick} className="common-submit-btn">Continue</div>}
                  {/* <button type="button" className="common-submit-btn">Continue</button> */}

                  {isLoading && <div className="lsb-loader-btn">
                    <img src={Img.loading} alt="" />Please wait ....
                  </div>}
                </div>
                {noSelectionError && <FlashMessage type="error" isvisible={true} message="Select atleast one option" />}
                <div className="col-12 BackBtnIsShow">
                  <div className="signinup-link-outer-bx slob-fdc fdc-two mb-0">
                    Back to
                    <Link to="/" className="signinup-link-two">Sign In</Link>
                  </div>
                </div>

              </div>

            </form>

          </div>
        </div>
      </section>

    </>
  );
};
