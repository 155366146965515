import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { Link,useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import FlashMessage from '../../components/FlashMessage';
import { useAuth, getLoggedInUserData, getToken } from '../../context/AuthContext';
// Phone Number Contry Code
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input';
import useApi from '../../utility/apiCall';
import API_URL from '../../config/config';
import { ThemeMode } from '../../context/ThemeMode';



// Select Contry
// import ReactFlagsSelect from "react-flags-select";

// Design Files
import PageHeader from './PageHeader';
import PageBackBtn from './PageBackBtn';
import PageSocialLogin from './PageSocialLogin';
import { TERMINATORLESS_TYPES } from '@babel/types';

// Images Common File
import * as Img from '../../components/Img';

const hidepassSvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
      <path strokeLinecap="round" strokeLinejoin="round" d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88" />
    </svg>
  )
}

const viewpassSvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
      <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
      <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
    </svg>
  )
}

const DownSvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
      <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
    </svg>
  )
}

const DotSvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24" strokeWidth={1.5} stroke="none" className="dotSvg">
      <path strokeLinecap="round" strokeLinejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
    </svg>
  )
}

const CheckSvg = () => {
  return (
  <svg style={{transform: "rotate(10deg)"}} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2.5} stroke="currentColor" className="size-6">
    <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" />
  </svg>
  )
}

export default function PageSignUp() {
  // --- ---
  const { isDarkMode } = ThemeMode();
  // --- ---
  
  const { apiCall } = useApi(); 
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const { dispatch, state } = useAuth();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const ref = queryParams.get('ref');
  const isInitialMount = useRef(true);
  // Tabing Code
  const [steps, setSteps] = useState('step1');
  const [acquisition, setAcquisition] = useState(null);
  const [ipMsg,setIpMsg] = useState('');


  useEffect(() => {
    const loggedInUser = getLoggedInUserData();
    const isUserLoggedIn = !!loggedInUser;

    if (isUserLoggedIn) {
      setUserLoggedIn(true);
      navigate('/dashboard')
    }
  }, [state.userData]);
  // Step 1

  // Step 1



  // Step 2
  // const [selected, setSelected] = useState("");
  // Step 2


  // Step 3
  // View passwords code
  const [showPasswords, setShowPasswords] = useState([false, false, false]);

  const togglePasswordVisibility = (index) => {
    const updatedShowPasswords = [...showPasswords];
    updatedShowPasswords[index] = !updatedShowPasswords[index];
    setShowPasswords(updatedShowPasswords);
  };
  // Step 3



  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [country, setCountry] = useState('');
  const [city, setCity] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [strength, setStrength] = useState([]); // Number of fulfilled criteria
  const [isStrengthMsg, setIsStrengthMsg] = useState(false); // Number of fulfilled criteria



  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [emailValidationError, setEmailValidationError] = useState(false);
  const [phoneNumberError, setPhoneNumberError] = useState(false);
  const [isCheckboxError, setIsCheckboxError] = useState(false);
  const [countryError, setCountryError] = useState(false);
  const [cityError, setCityError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [diffrentPasswordError,setDiffrentPasswordError] = useState(false);
  const [passwordLengthError,setPasswordLengthError] = useState(false);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);




  const [registerSuccsess, setRegisterSuccsess] = useState(false);
  const [registerError, setRegisterError] = useState(false);
  const [registerErrorMsg, setRegisterErrorMsg] = useState(false);
  const [emailAlreadyExist,setEmailAlreadyExist] = useState(false);
  const [phoneNumberAllreadyExist,setPhoneNumberAllreadyExist] = useState(false);
  const [disableBtn,setDisableBtn] = useState(false);
  const [isLoading,setIsLoading] = useState(false);
  const [isStepOneLoading, setIsStepOneLoading] = useState(false);

  const [countries, setCountries] = useState([]);

  const handleCheckboxChange = (e) => {
    setIsCheckboxChecked(e.target.checked);
    if(e.target.checked){
      setIsCheckboxError(false)
    }
  };

  const handlesetStep = async (stepId) => {
  setEmailError(false);
  setEmailAlreadyExist(false);
  setPhoneNumberError(false);
  setPhoneNumberAllreadyExist(false);
  
  if (firstName && lastName && email && phoneNumber && phoneNumber.length > 11 && isCheckboxChecked) {
    setIsStepOneLoading(true);
    try {
      console.log('Token before apiCall:', state.token);

      // Check if email exists
      const emailResponse = await apiCall(API_URL + 'check-email-exists', { email });
      if (emailResponse.data.exists) {
        setEmailAlreadyExist(true);
        setIsStepOneLoading(false);
        return; // Stop if email already exists
      }
      else if(emailResponse.data.success == "2") {
        setIsStepOneLoading(false);
        setIpMsg(emailResponse.data.data);
        return;     
      }

      // Check if phone number exists
      // const phoneResponse = await apiCall(API_URL + 'check-phone-number-exists', { phone: phoneNumber });
      // if (phoneResponse.data.exists) {
      //   setPhoneNumberAllreadyExist(true);
      //   setIsStepOneLoading(false);
      //   return; // Stop if phone number already exists
      // }

      // Proceed to the next step if both email and phone number do not exist
      setIsStepOneLoading(false);
      setSteps(stepId);
    } catch (error) {
      console.error('Error checking email or phone number existence:', error);
    }
  } else {
    if (!firstName) {
      setFirstNameError(true);
    }
    if (!email) {
      setEmailError(true);
    }
    if (!lastName) {
      setLastNameError(true);
    }
    if (!phoneNumber || phoneNumber.length < 11) {
      setPhoneNumberError(true);
    }
    if(!passwordError && strength.length<6){
      setIsStrengthMsg(true);
    }
    if (!isCheckboxChecked) {
      setIsCheckboxError(true);
    }
  }
};


const checkStrength = (password) => {
  const strengthCriteria = [
    { test: /.{8,}/, message: "8 characters", fulfilled: false,indexT:0 },
    { test: /[a-z]/, message: "1 lower-case letter", fulfilled: false,indexT:1 },
    { test: /[0-9]/, message: "1 number", fulfilled: false,indexT:2 },
    { test: /[!@#$%^&*]/, message: "1 special character: !@#$%^&*()-_{}.+", fulfilled: false,indexT:3 },
    { test: /[A-Z]/, message: "1 upper-case letter", fulfilled: false,indexT:4 },
  ];
  
  strengthCriteria.forEach((criteria) => {
    criteria.fulfilled = criteria.test.test(password);
  });

  // Create an array to hold both fulfilled and tested indexT values
  const allIndices = strengthCriteria.map(c => c.indexT); // All criteria indices
  const fulfilledIndices = strengthCriteria.filter(c => c.fulfilled).map(c => c.indexT); // Fulfilled criteria indices

  // Combine both arrays: fulfilled and tested
  const combinedIndices = [...new Set([...fulfilledIndices])];

  // Set the strength based on the combined indices
  setStrength(combinedIndices);
  return strengthCriteria;
};




  const handlesetStepTwo = (stepId) => {
    if (country && city) {
      setSteps(stepId);
    } else {
      if (!country) {
        setCountryError(true)
      }
      if (!city) {
        setCityError(true)
      }
    }

  };



  const userSignup = async () => {
if (firstName && lastName && email && phoneNumber && country && city  && password && confirmPassword) {
  setDisableBtn(true);
  setIsLoading(true);
  try {
    const response = await apiCall(API_URL +'register', {
      first_name : firstName,
      last_name : lastName,
      email: email,
      phone : phoneNumber,
      country : country,
      city:city,
      phone_country : phoneCountry,
      password : password,
      confirm_password: confirmPassword,
      ref:ref,
      acquisition:acquisition && acquisition !== null ? acquisition.id : null
    });
    setDisableBtn(false);
    if(response.data.message == 'Validation Error.' && response.data.data.email){
      setEmailAlreadyExist(true);
    }
    else if(response.data.message == 'Validation Error.' && response.data.data.phone){
      setPhoneNumberAllreadyExist(true);
    }
    else if(response.data.success == '0'){
      var token = response.data.data.token
      var userId= response.data.data.id
      setRegisterSuccsess(true);
      setTimeout(() => {
        navigate('/', { state: { email, phoneNumber,token,userId } }); 
      }, 2000);   
    }else if(response.data.success == '2'){
      setRegisterError(true);
      setRegisterErrorMsg(response.data.data.error);
    }else{
      console.log('Invalid inputs.')
    }
  } catch (error) {
    console.error('Error fetching data:', error);
  }
  setIsLoading(false);
} 

};




  const handlesetStepThree = () => {
    if (!password || !confirmPassword) {
      setPasswordError(!password); 
      setConfirmPasswordError(!confirmPassword);
      // setIsStrengthMsg(!isStrengthMsg);
      return;
    }

    if(isStrengthMsg){
      return;
    }
  
    if (password !== confirmPassword) {
      setDiffrentPasswordError(true);
      return;
    } else {
      setDiffrentPasswordError(false); 
    }
  
    if (password.length < 6 || confirmPassword.length < 6) {
      setPasswordLengthError(true);
      return;
    } else {
      setPasswordLengthError(false); 
    }
  
    userSignup();  
  };
  

  const handleEmailChange = (e) => {
    const inputEmail = e.target.value;
    setEmail(inputEmail);
    setEmailError(false);

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(inputEmail)) {
      setEmailValidationError(true);
    } else {
      setEmailValidationError(false);
    }
  };

  const handlePhoneNumberChange = (value) => {
    if (value && value.trim() !== '') {
      setPhoneNumber(value);
      setPhoneNumberError(false);
    } else {
      setPhoneNumberError(true);
    }
  };


  useEffect(() => {
  const fetchCountriesData = async () => {
    try {
      const response = await apiCall(API_URL +'get-countries', {
        nopaginate: "1",
      });
      if (response.data.success == '0') {
        setCountries(response.data.data);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }

  };
  if (isInitialMount.current) {
    isInitialMount.current = false;
    fetchCountriesData();
    if(ref){
      updateClickCount();
    }
  }
 
}, []);

const updateClickCount = async() => {
  try {
    const response = await apiCall(API_URL +'add-ref-clicks', {
      ref: ref,
      link:window.location.href,
      device_type:3
    });
    if (response.data.success == '0') {
      setAcquisition(response.data.data);
    }
  } catch (error) {
    console.error('Error fetching data:', error);
  }
}

  const handleCountryChange = (e) => {
    setCountry(e.target.value);
    setCountryError(false);
  };

  const [phoneCountry, setPhoneCountry] = React.useState('AE');

  const handlePhoneCountryChange = (countryCode) => {
    setPhoneCountry(countryCode);
  };
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setPasswordError(false);
    console.log('strength.length', checkStrength(e.target.value));
    const fulfilledCount = checkStrength(e.target.value).filter(criteria => criteria.fulfilled).length;
    console.log('fulfilledCount', fulfilledCount);
    
    if(fulfilledCount == 5){
      setIsStrengthMsg(false);
    } else {
      setIsStrengthMsg(true);
    }
    setDiffrentPasswordError(false);
    setPasswordLengthError(false);
    // checkStrength(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    setConfirmPasswordError(false);
    setDiffrentPasswordError(false);
    setPasswordLengthError(false);
  };



  useEffect(() => {
    if (registerSuccsess || registerError ||  emailAlreadyExist || phoneNumberAllreadyExist) {
      setTimeout(() => {
        setRegisterSuccsess(false);
        setRegisterError(false);
        setEmailAlreadyExist(false);
        setPhoneNumberAllreadyExist(false);
      }, 2000);
    }
  }, [registerSuccsess,registerError,emailAlreadyExist,phoneNumberAllreadyExist]);

  //  const handleKeyPress = (event) => {
  //   if (event.key === 'Enter') {
  //     event.preventDefault(); // Prevent default form submission
  //     handlesetStep('step3'); // Call the sign-in function
  //   }
  // };
  const handleStepPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent default form submission
      handlesetStep('step2'); // Call the sign-in function
    }
  };



  return (
    <>
      <Helmet>
        <title>Register | Winpro Fx</title>
      </Helmet>

      <PageHeader />

      {steps === "step1" ? (
        <PageBackBtn />
      ) : steps === "step2" ? (
        <PageBackBtn to={null} onClick={() => handlesetStep('step1')} />
      ) : steps === "step3" ? (
        <PageBackBtn to={null} onClick={() => handlesetStep('step2')} />
      ) : null}


      <section className="lgh-wraper">
        <div className="container-lg cl-custome">

          {/* --- Step 1 start --- */}
          <div className={`signinup-screen ${isDarkMode ? " " : "signinup-group-style"} ${steps === 'step1' ? '' : 'd-none'} `} id="step1">
            <div className="signinup-header">
              <div className="screen-hed">Create an account</div>
            </div>

            <form className="signinup-width" onKeyPress={handleStepPress}>
              <div className="row">

                <div className="col-6 pe-1">
                  {/* error-group */}
                  <div className={`signinup-group ${firstNameError ? 'error-group' : ''}`}>
                    <input type="text" value={firstName} onChange={(e) => { setFirstNameError(false); setFirstName(e.target.value); }} placeholder="Enter first name" />
                    {/* <div className="group__label">First Name</div> */}
                    {firstNameError && <span className="form-error-msg">First name is required</span>}
                  </div>
                </div>

                <div className="col-6 ps-1">
                  {/* error-group */}
                  <div className={`signinup-group ${lastNameError ? 'error-group' : ''}`}>
                    <input type="text" value={lastName} onChange={(e) => { setLastNameError(false); setLastName(e.target.value); }} placeholder="Enter last name" />
                    {/* <div className="group__label">Last Name</div> */}
                    {lastNameError && <span className="form-error-msg">Last name is required</span>}
                  </div>
                </div>

                <div className="col-12">
                  {/* error-group */}
                  <div className={`signinup-group ${emailError ? 'error-group' : ''}`}>
                    <input type="email" value={email} onChange={handleEmailChange} placeholder="Enter Email" />
                    {/* <div className="group__label">Enter Email</div> */}
                    {emailError && <span className="form-error-msg">Email is required</span>}
                    {emailValidationError && <span className="form-error-msg">Invalid email format</span>}
                    {emailAlreadyExist && <span className="form-error-msg">Email already exists</span>}
                  </div>
                </div>

                <div className="col-12">
                  {/* error-group */}
                  <div className={`signinup-group sg-country-code ${phoneNumberError ? 'error-group' : ''}`}>
                    <PhoneInput
                      international
                      value={phoneNumber}
                      // countryCallingCodeEditable={false}
                      defaultCountry="AE"

                      placeholder="Enter Phone Number"
                      onChange={handlePhoneNumberChange}
                      onCountryChange={handlePhoneCountryChange} // Capture the country
                    />
                    {/* <input type="number" placeholder="Enter Phone Number" /> */}
                    {/* <div className="group__label">Enter Phone Number</div> */}
                    {phoneNumberError && <span className="form-error-msg">Phone number is required</span>}
                    {phoneNumberAllreadyExist && <span className="form-error-msg">Phone number already exists</span>}
                  </div>
                </div>

                <div className="col-12">
                  <div className="signinup-group-checkmark">
                    <input className="d-none" type="checkbox" checked={isCheckboxChecked} onChange={handleCheckboxChange} id="checkmark" />
                    <label className="checkmark-lable" htmlFor="checkmark"><span className="checkmark-icon"></span>By Register i agree that i’m 18 years of age or older, ot the <a href="https://winprofx.com/privacy-policy" rel="noreferrer" target="_blank">Privacy Policy</a> & <a href="https://winprofx.com/risk-disclosure" rel="noreferrer" target="_blank">Risk Disclosure</a>.</label>
                    {isCheckboxError && <span className="form-error-msg">Please agree you are 18 years old</span>}
                  </div>
                </div>
                {ipMsg && <FlashMessage type="error" isvisible={true} message={ipMsg} />}
                <div className="col-12">
                  { !isStepOneLoading &&
                  <button onClick={() => handlesetStep('step2')} type="button" className="common-submit-btn">Continue</button>
                  }  

                  { isStepOneLoading && <div className="lsb-loader-btn">
                      <img src={Img.loading} alt="" />Please wait ...
                  </div>
                  }
                </div>

                <div className='col-12'>
                  <div className="signinup-link-outer-bx slob-fdc fdc-two mb-0">
                    Already have an account?
                    <Link to="/" className="signinup-link-two">Sign in</Link>
                  </div>
                </div>

              </div>

            </form>
            {/* <PageSocialLogin /> */}
          </div>
          {/* --- Step 1 end --- */}


          {/* --- Step 2 start --- */}
          <div className={`signinup-screen ${isDarkMode ? " " : "signinup-group-style"} ${steps === 'step2' ? '' : 'd-none'} `} id="step2">

            <div className="signinup-header">
              <div className="screen-hed">Select country & city</div>
            </div>

            <form className="signinup-width">
              <div className="row">

                {/* <div className="col-12">
                  <div className="signinup-group sg-country-select">
                       <ReactFlagsSelect
                          selectedSize={14}
                          className="menu-flags"
                          selectButtonClassName={`menu-flags-button ${selected ? "active" : " "}`}
                          searchable
                          searchPlaceholder="Search countries"
                          selected={selected}
                          onSelect={(code) => setSelected(code)}
                        />
                  </div>
                </div> */}

                <div className="col-12">
                  {/* error-group */}
                  <div className={`signinup-group sgr-icon sgri-select ${countryError ? 'error-group' : ''}`}>
                    <select name="country" id="country" value={country} onChange={handleCountryChange}>
                      <option value="">Select Country</option>
                      {countries && countries.map((item, index) => (
                        <option key={index} value={item.id}>{item.name}</option>
                      ))}
                    </select>
                    <span className="group_right_icon">
                      {DownSvg()}
                    </span>
                    {countryError && <span className="form-error-msg">Country is required</span>}
                  </div>
                </div>

                <div className="col-12">
                  {/* error-group */}
                  <div className={`signinup-group ${cityError ? 'error-group' : ''}`}>
                    <input type="text" value={city} onChange={(e) => { setCityError(false); setCity(e.target.value); }} placeholder="Enter City" />
                    {/* <div className="group__label">Enter City</div> */}
                    {cityError && <span className="form-error-msg">City is required</span>}
                  </div>
                </div>

                <div className="col-12">
                  <button onClick={() => handlesetStepTwo('step3')} type="button" className="common-submit-btn">Continue</button>

                  {/* <div className="lsb-loader-btn">
                      <img src={Img.loading} alt="" />Please wait ....
                  </div> */}
                </div>

                <div className='col-12 BackBtnIsShow'>
                  <div className="signinup-link-outer-bx slob-fdc fdc-two mb-0">
                    Back to
                    <div onClick={() => handlesetStep('step1')} className="signinup-link-two">Previous</div>
                  </div>
                </div>

              </div>

            </form>

          </div>
          {/* --- Step 2 start --- */}


          {/* --- Step 3 start --- */}
          <div className={`signinup-screen ${isDarkMode ? " " : "signinup-group-style"} ${steps === 'step3' ? '' : 'd-none'} `} id="step3">

            <div className="signinup-header">
              <div className="screen-hed">Set a password</div>
            </div>

            <form className="signinup-width">
              <div className="row">

                {[0, 1].map((index) => (
                  <div className={`col-12`} key={index}>
                     <div className={`signinup-group sgr-icon ${(isStrengthMsg && index === 0) || passwordError || confirmPasswordError ? 'error-group' : ''}`}>
                      <input
                        type={showPasswords[index] ? 'text' : 'password'}
                        placeholder={`${index === 0 ? 'Password' : 'Confirm Password'}`}
                        value={index === 0 ? password : confirmPassword}
                        onChange={index === 0 ? handlePasswordChange : handleConfirmPasswordChange}
                      />
                      <span className={`group_right_icon ${showPasswords[index] ? "active" : ""}`} onClick={() => togglePasswordVisibility(index)} >
                        {showPasswords[index] ? viewpassSvg() : hidepassSvg()}
                      </span>
                      {/* Display error message for password */}
                      {index === 0 && passwordError && <span className="form-error-msg">Password is required</span>}
                      {/* Display error message for confirm password */}
                      {index === 1 && confirmPasswordError && <span className="form-error-msg">Confirm passwords is required</span>}

                      {diffrentPasswordError && <span className="form-error-msg">Password and confirm passwords did not matched</span>}
                      {index === 0 && !passwordError && isStrengthMsg && <span className="form-error-msg">Match with criteria</span>}

                      {index === 0 && (
                        <div className="password-rules-bx">
                          <div className={strength.includes(0) ? "prb-item color-green" : "prb-item"}>
                            {strength.includes(0) ? CheckSvg() : DotSvg()} 8 characters
                          </div>
                          <div className={strength.includes(1) ? "prb-item color-green" : "prb-item"}>
                            {strength.includes(1) ? CheckSvg() : DotSvg()} 1 lower-case letter
                          </div>
                          <div className={strength.includes(2) ? "prb-item color-green" : "prb-item"}>
                            {strength.includes(2) ? CheckSvg() : DotSvg()} 1 number
                          </div>
                          <div className={strength.includes(3) ? "prb-item color-green" : "prb-item"}>
                            {strength.includes(3) ? CheckSvg() : DotSvg()} 1 special character: !@#$%^&*()-_{}.+ 
                          </div>
                          <div className={strength.includes(4) ? "prb-item color-green" : "prb-item"}>
                            {strength.includes(4) ? CheckSvg() : DotSvg()} 1 upper-case letter
                          </div>
                        </div>
                      )}


                    </div>
                  </div>
                ))}

                <div className="col-12">
              {!isLoading && <div onClick={handlesetStepThree} className={`common-submit-btn ${disableBtn ? 'disable' : ''}`} disabled={disableBtn}>Continue</div>}
                  {/* <button type="button" className="common-submit-btn">Continue</button> */}

                  {isLoading && <div className="lsb-loader-btn">
                      <img src={Img.loading} alt="" />Please wait ....
                  </div>}
                </div>
                {registerSuccsess && <FlashMessage type="success" isvisible={true} message="Register successfull" />}
                {registerError && <FlashMessage type="error" isvisible={true} message={registerErrorMsg} />}
                {/* {emailAlreadyExist && <FlashMessage type="error" isvisible={true} message="Email already exist." />}
                {phoneNumberAllreadyExist && <FlashMessage type="error" isvisible={true} message="Phone Number already exist." />} */}
                <div className="col-12 BackBtnIsShow">
                  <div className="signinup-link-outer-bx slob-fdc fdc-two mb-0">
                    Back to
                    <div onClick={() => handlesetStep('step2')} className="signinup-link-two">Previous</div>
                  </div>
                </div>

              </div>
            </form>

          </div>
          {/* --- Step 3 start --- */}

        </div>
      </section>

    </>
  );
};
