import React from 'react';
import ReactDOM from 'react-dom/client';
import { AuthProvider } from './context/AuthContext'
import { ThemeModeProvider } from './context/ThemeMode';

// Css Links
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/font-awesome.min.css';
import './css/themecolor.css';
import './css/commonform.css';
import './css/signinup.css';
import './css/header.css';
import './css/account.css';
import './css/style.css';
import './css/lighttheme.css';

// Scripts
import 'bootstrap/dist/js/bootstrap.bundle.min.js';


import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThemeModeProvider>
      <AuthProvider>
        <App />
      </AuthProvider>
    </ThemeModeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
