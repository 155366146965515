import React, { useState, useEffect } from 'react';
import {Link} from "react-router-dom";

// Images Common File
import * as Img from '../../components/Img';
import Icons from "../../components/icons"

import lghBg from '../../img/bg/lghBg3.webp';

import { ThemeMode } from '../../context/ThemeMode';

export default function PageHeader() {

  // --- Header Js Start --- //
  const [headerSticky, setheaderSticky] = useState("");

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 10) {
        setheaderSticky("active");
      } else {
        setheaderSticky("");
      }
    };

    // Attach the event listener when the component mounts
    window.addEventListener('scroll', handleScroll);

    // Remove the event listener when the component unmounts to avoid memory leaks
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []); // Empty dependency array ensures the effect runs only once when the component mounts
  // --- Header Js End --- //
  // --- ---
  const { isDarkMode, toggleTheme } = ThemeMode();
  // --- ---
  return (
    <>
      <header className={`login_register_header ${headerSticky}`}>
        <div className="lgh-inner maxwidth1920">

          <Link to="/" rel="noreferrer" className="lgh-logo-bx">
            <img src={Img.logo_light} alt="" />
          </Link>

        </div>
      </header>

      <div className="lghBg-main-bx">
        <img src={lghBg} alt="" />
      </div>

      <div onClick={toggleTheme} title={isDarkMode ? "Light mode" : "Dark mode"} className="SignInUpThemeMode">
        {isDarkMode ? <Icons.LightSvg/> : <Icons.MoonSvg />}
      </div>
    </>
  );
};
