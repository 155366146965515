import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import { MenuProvider } from '../context/MenuContext';
import { AudioProvider } from '../context/AudioContext';
import RequireAuth from '../RequireAuth';


// Demo & testing  Url Here Start
import test_two from '../containers/PageSignInUp/test_two';
// Demo & testing  Url Here End

// Header & Sidemenu
import CommonLayout from '../containers/PageCommonLayout/CommonLayout';

// Header section
import ScrollToTop from "./ScrollToTop";
import Loader from "./Loader";

// Login & register Pages
import PageSignIn from "../containers/PageSignInUp/PageSignIn";

import PageSignUp from "../containers/PageSignInUp/PageSignUp";
import PageSignUpStep2 from "../containers/PageSignInUp/PageSignUpStep2";
import PagePassword from "../containers/PageSignInUp/PagePassword";

import PageVerification from "../containers/PageSignInUp/PageVerification";
import PageSignOTP from "../containers/PageSignInUp/PageSignOTP";

import PageRestore from "../containers/PageSignInUp/PageRestore";
import PageResetPassword from "../containers/PageSignInUp/PageResetPassword";

// Dashboard Page
import PageDashboard from "../containers/PageDashboard/PageDashboard";

// MT5 Pages
import PageMT5 from "../containers/PageMT5/PageMT5";
import PageMt5History from "../containers/PageMT5/PageMt5History";

// Wallet Pages
import PageWallet from "../containers/PageWallet/PageWallet";
import PageWalletDetail from "../containers/PageWallet/PageWalletDetail";

// Funds Pages
import PageDeposit from "../containers/PageFunds/PageDeposit";
import PageWithdraw from "../containers/PageFunds/PageWithdraw";
import PageTransfer from "../containers/PageFunds/PageTransfer";

// Profile Page
import PageSettings from "../containers/PageProfile/PageSettings";

// Finance page
// import PageFinance from "../containers/PageFinance/PageFinance";

// verifications page
// import PageVerifications from "../containers/PageVerifications/PageVerifications";
import PageVerifications2 from "../containers/PageVerifications/PageVerifications2";
import PageverificationsForm from "../containers/PageVerifications/PageverificationsForm";

// security page
import PageSecurity from "../containers/PageSecurity/PageSecurity";

// IB Portal pages
import PageIBDashboard from "../containers/PageIB/PageIBDashboard";
import PageIBRequest from "../containers/PageIB/PageIBRequest";
import PageIBReportes from "../containers/PageIB/PageIBReportes";
import PageIBMyClient from "../containers/PageIB/PageIBMyClient";
import PageIBFaqs from "../containers/PageIB/PageIBFaqs";

// Transactions Page
import PageTransactions from "../containers/PageTransactions/PageTransactions";

// Platefrom Page
import PagePlatfrom from "../containers/PagePlatfrom/PagePlatfrom";

// Analytics Pages
import PageSummary from "../containers/PageAnalytics/PageSummary";
import PageOrderHistory from "../containers/PageAnalytics/PageOrderHistory";

// Notifications Page
import PageNotifications from "../containers/PageNotifications/PageNotifications";

// Suggest page
import PageSuggest from "../containers/PageSuggest/PageSuggest";

// Trading Page
import PageTrading from "../containers/PageTrading/PageTradeView";
import PageTrading2 from "../containers/PageTrading2/PageTradeView";


// Footer & Other common section
import FlashMessage from "../components/FlashMessage";

// Live chat widget
// import { LiveChatWidget } from '@livechat/widget-react';

export const pages = [

  // Demo & testing  Url Here Start
  { path: '/test', component: test_two },
  // Demo & testing  Url Here End

  // Login & Register Pages
  { path: "/", component: PageSignIn },

  { path: "/register", component: PageSignUp, allowedRoles: false },
  { path: "/register2", component: PageSignUpStep2, allowedRoles: false },
  { path: "/password", component: PagePassword, allowedRoles: true },

  { path: "/verification", component: PageVerification, allowedRoles: false },
  { path: "/restoreotp", component: PageSignOTP, allowedRoles: false },

  { path: "/restore", component: PageRestore, allowedRoles: false },
  { path: "/resetpassword", component: PageResetPassword, allowedRoles: false },

  // Dashboard Page
  { path: "/dashboard", component: PageDashboard, allowedRoles: true },

  // MT5 page
  { path: "/mt5", component: PageMT5, allowedRoles: true },
  { path: "/mt5-trade-history/:account", component: PageMt5History, allowedRoles: true },

  // Wallet page
  { path: "/wallet", component: PageWallet, allowedRoles: true },
  { path: "/wallet/:id", component: PageWalletDetail, allowedRoles: true },
  { path: "/wallet-detail", component: PageWalletDetail },
  
  // Funds
  { path: "/deposit", component: PageDeposit },
  { path: "/withdraw", component: PageWithdraw },
  { path: "/transfer", component: PageTransfer },

  // MT5
  { path: "/deposit/:type/:id", component: PageDeposit },
  //{ path: "/withdraw/:id", component: PageWithdraw },
  { path: "/withdraw/:type/:id", component: PageWithdraw },
  
  // Profile Pages
  { path: "/settings", component: PageSettings, allowedRoles: true },

  // Verifications pages
  // { path: "/verifications", component: PageVerifications },
  // { path: "/verifications2", component: PageVerifications2 },
  { path: "/verifications", component: PageVerifications2 },
  { path: "/verifications-form/:type", component: PageverificationsForm },

  // Finance Page
  // { path: "/finance", component: PageFinance },

  // security pages
  { path: "/security", component: PageSecurity },
  
  //IB Portal
  { path: "/ib-dashboard", component: PageIBDashboard },
  { path: "/ib-request", component: PageIBRequest },
  { path: "/ib-reports", component: PageIBReportes },
  { path: "/ib-myclient/:id", component: PageIBMyClient },
  { path: "/ib-faqs", component: PageIBFaqs },

  // Transactions page 
  { path: "/transactions", component: PageTransactions },

  // Platfrom page 
  { path: "/platfrom", component: PagePlatfrom },
  
  // Notifications page 
  { path: "/notifications", component: PageNotifications },
  
  // Suggest page 
  { path: "/suggest", component: PageSuggest },

  // Analytics Pages
  { path: "/analytics-summary", component: PageSummary },
  { path: "/order-history", component: PageOrderHistory },


  // Trading Terminal
  { path: "/trading", component: PageTrading},
  { path: "/trading/:account_id", component: PageTrading},
  { path: "/trading2", component: PageTrading2}
  
];

// Define paths where the 'ofh-disabled' class should be added to the body
const ofhDisabledPaths = [
  "/",
  "/register",
  "/register2",
  "/password",
  "/verification",
  "/restoreotp",
  "/restore",
  "/resetpassword"
];

// Define paths where CommonLayout should be hidden
const hideLayoutPaths = [
  "/",
  "/register",
  "/register2",
  "/password",
  "/verification",
  "/restoreotp",
  "/restore",
  "/resetpassword",
  "/trading",
  "/trading/:account_id"
];

const MyRoutes = () => {

  const location = useLocation();

  // Add or remove 'ofh-disabled' class from body based on the current path
  useEffect(() => {
    if (ofhDisabledPaths.includes(location.pathname)) {
      document.body.classList.add('signinup-page');
    } else {
      document.body.classList.remove('signinup-page');
    }
  }, [location.pathname]);

  const hideLayout = hideLayoutPaths.some(path => {
    // Convert route path pattern to match exact paths or parameters
    const regex = new RegExp(`^${path.replace(/:[^\s/]+/, '[^/]+')}$`);
    return regex.test(location.pathname);
  });

  return (
    <>
      <ScrollToTop />
      <Loader />

      {/* <main className="main-wrapper"> */}
      <CommonLayout hide={hideLayout}>
        <Routes>
          {pages.map(({ component: Component, path,allowedRoles }, index) => {
            if (allowedRoles && allowedRoles === true) {
              return (
                <Route key={index} element={<RequireAuth allowedRoles={[true]} />} >
                  <Route element={<Component />} path={path} />
                </Route>
              );
            }
            else
            {
              return <Route key={index} element={<Component />} path={path} />;
            }
          })}
        </Routes>
      </CommonLayout>
      {/* </main> */}

      <FlashMessage />
      {/*<LiveChatWidget license="16090353"/>*/}   
    </>
  );
};

function MainRoutes() {
  return (
    <Router>
      <AudioProvider>
        <MenuProvider>
            <MyRoutes />
        </MenuProvider>
      </AudioProvider>
    </Router>
  );
}

export default MainRoutes;