import React, { useState, useEffect, useRef } from 'react'; 
import { Link } from 'react-router-dom';
import { useAuth, getLoggedInUserData, getToken } from '../../context/AuthContext';
import useApi from '../../utility/apiCall';
import API_URL from '../../config/config';

// Icon Files
import Icons from '../../components/icons';

export default function IBWalletBalances({ibBalance,programId}) {
    const { apiCall } = useApi();
    const [user, setUser] = useState('')
    const { state } = useAuth();
    const token = state.token || '';
    const { dispatch } = useAuth();
    const [total, setTotalBal] = useState('0.00');
    const [currency, setCurrency] = useState('');
    const [walletId, setWalletId] = useState(null);
    const isInitialMount = useRef(true);


    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
            const loggedInUser = getLoggedInUserData();
            const isUserLoggedIn = !!loggedInUser;
            if (isUserLoggedIn) {
                if (loggedInUser.id && token) {
                    fetchTotalBalance();
                }
            }
        }
    }, [state.userData]); 
// console.log(programId);

    const fetchTotalBalance = async () => {
        try {
            const response = await apiCall(API_URL +'get-user-ib-wallet-total-balance', {
                user_id: state?.userData?.id,
                token: token,
                program_id:programId
            });
            if (response.data.success == '0') {
                // setTotalBal(ibBalance);
                setCurrency(response.data.data.currency);
                setWalletId(response.data.data.wallet_id);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    return (
        <div className="common-box">
            <div className="wallet-balances-bx IB-wbb">
                <div className="wbb-inner-bx">
                    <div className="wbb-i-label">IB Reward</div>
                    <div className="wbb-i-balances">{ibBalance}<span>{currency}</span></div>
                </div>
                <div className="wallet-current-btns d-flex flex-wrap gap-2">
                    {/* <Link to="/deposit" className="common-btn-item cbi-small cbi-fill"><span><Icons.DepositSvg/>Deposit</span></Link> */}
                    {walletId && (
                        <>
                            <Link to={`/withdraw/ib/${walletId}`} className="common-btn-item cbi-small cbi-fill">
                                <span>
                                    <Icons.WithdrawSvg /> Withdraw
                                </span>
                            </Link>
                            <Link to={`/transfer`} className="common-btn-item cbi-small cbi-fill">
                                <span>
                                    <Icons.TransactionsSvg /> Transfer
                                </span>
                            </Link>
                        </>
                    )}

                    {/* <Link to="/transfer" className="common-btn-item cbi-small cbi-fill"><span><Icons.TransactionsSvg/>Internal Transfer</span></Link> */}
                </div>
            </div>
        </div>
    )
}
