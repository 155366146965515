import React, { useState, useEffect, useRef } from "react";
import { useAuth, getLoggedInUserData } from "../../context/AuthContext";
import useApi from "../../utility/apiCall";
import API_URL from "../../config/config";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import Icons from "../../components/icons";
import * as Img from "../../components/Img";
import Input from "../../components/CommonInput/Input";
import DatePickerComponent from "../../components/CommonInput/DatePickerComponent";
import SelectDropItem from "../../components/CommonInput/SelectDropItem";
import Textarea from "../../components/CommonInput/Textarea";
import RadioInput from "../../components/CommonInput/RadioInput";
import SpecificationAvailability from "./SpecificationAvailability";
import FlashMessage from "../../components/FlashMessage";

export default function VerificationSteps() {
  // ---
  const { apiCall } = useApi();
  const [user, setUser] = useState("");
  const { state, dispatch } = useAuth();
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const isInitialMount = useRef(true);
  const token = state.token || "";

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      const loggedInUser = getLoggedInUserData();
      const isUserLoggedIn = !!loggedInUser;
      if (isUserLoggedIn) {
        setUserLoggedIn(true);
        setUser(loggedInUser);
      }
      fetchVerification();
      applicationGroup();
    }
  }, [state.userData]);

  const [isVerificationModal, setIsVerificationModal] = useState(false);
  const [isVerificationData, setIsVerificationData] = useState(false);
  const [verificationLabel, setVerificationLabel] = useState("Level 0");
  const [verificationLevel, setVerificationLevel] = useState(null);

  const handleVerificationClose = () => {
    setIsVerificationModal(false);
  };

  const handleVerificationShow = () => {
    setIsVerificationModal(true);
    handleVerificationSteps("verificationSteps1");
  };

  // ---
  const [verificationSteps, setVerificationSteps] =
    useState("verificationSteps1");
  const handleVerificationSteps = (stepId) => {
    setVerificationSteps(stepId);
  };
  

  // ---
  const [otp, setOtp] = useState(["", "", "", ""]);
  const inputRefs = [useRef(), useRef(), useRef(), useRef()];

  const handleChange = (e, index) => {
    const value = e.target.value;

    if (!isNaN(value) && value.length <= 1) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      if (index < otp.length - 1 && value !== "") {
        inputRefs[index + 1].current.focus();
      }
    } else if (value === "" && index > 0) {
      // If backspace is pressed and the input field is empty, move focus to the previous input
      inputRefs[index - 1].current.focus();
    }
  };
  useEffect(() => {
    if (otp.every((element) => element.trim() !== "")) {
      setOtpErrors((prev) => ({ ...prev, otpError: "" }));
    }
  }, [otp]);

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace") {
      e.preventDefault();
      if (index > 0) {
        // Move focus to the previous input
        inputRefs[index - 1].current.focus();
      }
      // Clear the current input
      const newOtp = [...otp];
      newOtp[index] = "";
      setOtp(newOtp);
    }
  };

  // ---
  const [isIdentityModal, setIsIdentityModal] = useState(false);

  const handleIdentityClose = () => {
    setIsIdentityModal(false);
  };

  const handleIdentityShow = () => {
    setIsIdentityModal(true);
  };
  // useEffect(() => {
  //   if (isIdentityModal) {
  //     applicationGroup();
  //   }
  // }, [isIdentityModal]);
  // ---
  const [identitySteps, setIdentitySteps] = useState("identitySteps1");
  const handleIdentitySteps = (stepId) => {
    setIdentitySteps(stepId);
  };

  // ---
  const [isMessageModal, setIsMessageModal] = useState(false);

  const handleMessageClose = () => {
    setIsMessageModal(false);
  };

  const handleMessageShow = () => {
    setIsMessageModal(true);
  };

  // ---
  const regionOption = [
    { name: "Select Region", selected: true },
    { name: "Delhi", selected: false },
    { name: "Gujarat", selected: false },
    { name: "Kolkata", selected: false },
    { name: "Maharashtra", selected: false },
  ];
  const [showLoader, setShowLoader] = useState(false);
  const [showOtpLoader, setShowOtpLoader] = useState(false);
  const [showNameLoader, setShowNameLoader] = useState(false);
  const [verifyEmailPhone, setVerifyEmailPhone] = useState(null);
  const [application, setApplication] = useState(null);
  const [documentVerified, setDocumentVerified] = useState(false);
  const [addressVerified, setAddressVerified] = useState(false);
  const [idStatus, setIdStatus] = useState(null);
  const [addressStatus, setAddressStatus] = useState(null);
  const [otpErrors, setOtpErrors] = useState({});
  const [otpSuccsess, setOtpSuccsess] = useState(false);
  const [otpError, setOtpError] = useState(false);
  const [otpSuccess, setOtpSuccess] = useState(false);
  const [emailSuccess, setEmailSuccess] = useState(false);
  const [verifiedStep, setVerifiedStep] = useState("verification-status-bx2");
  const [completedSteps, setCompletedSteps] = useState(0);
  const [timer, setTimer] = useState(30);

  {
    /* verified-step1, verified-step2, verified-step3 */
  }

  const fetchVerification = async () => {
    setShowOtpLoader(true);
    try {
      const response = await apiCall(
        API_URL + "get-user-verification-level",
        {
          user_id: state?.userData?.id,
          token: state?.token,
        },
        {}
      );
      if (response.data.success == "0") {
        setVerifyEmailPhone(response.data.data);
        setShowOtpLoader(false);
      }
    } catch (error) {
      console.error("An error occurred");
    }
  };
  const fetchUserSteps = async () => {
    try {
      const response = await apiCall(
        API_URL + "get-user-profile",
        {
          user_id: state?.userData?.id,
          token: state?.token,
        },
        {}
      );
      if (response.data.success == "0") {
        localStorage.setItem("userData", JSON.stringify(response.data.data));
        localStorage.setItem("token", token);
        dispatch({
          type: "LOGIN",
          payload: { user: response.data.data, token: token },
        });
      }
    } catch (error) {
      console.error("An error occurred");
    }
  };
  const applicationGroup = async () => {
    try {
      const response = await apiCall(
        API_URL + "get-application-group",
        {
          user_id: state?.userData?.id,
          token: state?.token,
        },
        {}
      );
      if (response.data.success == "0") {
        setApplication(response.data.data);
        const applicationData = response.data.data;
        const idProofItem = applicationData.find(
          (item) => item.document_group_name === "Id proof"
        );
        const addressProofItem = applicationData.find(
          (item) => item.document_group_name === "AddressProof"
        );
        if (idProofItem) {
          setDocumentVerified(true);
          setIdStatus(idProofItem.application_status);
        }
        if (addressProofItem) {
          setAddressVerified(true);
          setAddressStatus(addressProofItem.application_status);
        }
      }
    } catch (error) {
      console.error("An error occurred");
    }
  };
  //let verificationLevel = "";
  useEffect(() => {    
    if (state.userData.step3_verified) {
      setVerificationLevel("Fully verified");
      setVerifiedStep("verified-step3");
      setCompletedSteps(3);
    } else if (state.userData.step2_verified) {
      setVerificationLevel("Partially verified");
      setVerifiedStep("verified-step2");
      setCompletedSteps(2);
    } else if (state.userData.step1_verified) {
      setVerificationLevel("Partially verified");
      setVerifiedStep("verified-step1");
      setCompletedSteps(1);
    } else {
      setVerificationLevel("Unverified");
      setVerifiedStep("verification-status-bx2"); // Or some default value
      setCompletedSteps(0);
    }
  }, [state]);
  useEffect(() => {
    if (otpSuccsess && timer > 0) {
      const countdown = setTimeout(() => {
        setTimer(timer - 1);
      }, 1000);
      return () => clearTimeout(countdown); // Cleanup timeout
    }
  }, [timer, otpSuccsess]);

  useEffect(() => {
    // Set up the interval to call the function every 10 seconds
    const intervalId = setInterval(fetchUserSteps, 10000); // 10000ms = 10 seconds
    const intervalGroup = setInterval(applicationGroup, 10000); // 10000ms = 10 seconds

    // Clean up the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
      clearInterval(intervalGroup);
    };
  }, []);
  const handalSendOtp = async () => {
    try {
      setShowLoader(true);
      const response = await apiCall(
        API_URL + "send-user-verification-otp",
        {
          user_id: state?.userData?.id,
          token: state?.token,
          verification_type: !state?.userData?.email_verified ? "email" : "phone",
        },
        {}
      );
      if (response.data.success == "0") {
        setIsVerificationData(response.data.data);
        setVerificationLabel(response.data.data.caption);
        setShowLoader(false);
        setOtpSuccsess(true);
        setTimer(30);  // Start the timer
      }
    } catch (error) {
      console.error("An error occurred");
      setShowLoader(false);
    }
  };
  
  const handalReSendOtp = async () => {
    try {
      setShowLoader(true);
      setOtpSuccsess(false);
      const response = await apiCall(
        API_URL + "send-user-verification-otp",
        {
          user_id: state?.userData?.id,
          token: state?.token,
          verification_type: !state?.userData?.email_verified ? "email" : "phone",
        },
        {}
      );
      if (response.data.success == "0") {
        setIsVerificationData(response.data.data);
        setVerificationLabel(response.data.data.caption);
        setShowLoader(false);
        setOtpSuccsess(true);
        setTimer(30);  // Start the timer
      }
    } catch (error) {
      console.error("An error occurred");
      setShowLoader(false);
    }
  };
  
  const handalVerifyOtp = async () => {
    const valErrors = validateOtpFields();
    console.log(valErrors);
  
    if (Object.keys(valErrors).length === 0) {
      setShowOtpLoader(true);
      setOtpSuccess(false);
      setOtpError(false);
      try {
        const response = await apiCall(
          API_URL + "verify-user-otp",
          {
            user_id: state?.userData?.id,
            token: state?.token,
            verification_type: !state?.userData?.email_verified ? "email" : "phone",
            otp: otp,
          },
          {}
        );
        if (response.data.success == "0") {
          localStorage.setItem("userData", JSON.stringify(response.data.data));
          localStorage.setItem("token", token);
          dispatch({
            type: "LOGIN",
            payload: { user: response.data.data, token: token },
          });
          setOtpSuccess(true);
          setOtp(["", "", "", ""]);
          setTimer(30); // Reset the timer to 30 on verification
          handleVerificationClose();
        } else {
          setOtpError(true);
        }
        setShowOtpLoader(false);
      } catch (error) {
        console.error("An error occurred");
        setShowOtpLoader(false);
      }
    } else {
      setOtpErrors(valErrors);
    }
  };

  const validateOtpFields = () => {
    const newErrors = {};
    if (!otp || otp.some((element) => element.trim() == "")) {
      newErrors.otpError = "Otp is required";
    }
    return newErrors;
  };
  const getSubLabel = () => {
    if (!state.userData.email_verified) {
      return (
        <div className="cmh-sub-lable">
          An SMS code was sent to <span>{state.userData.email}</span>
        </div>
      );
    } else if (!state.userData.phone_verified) {
      return (
        <div className="cmh-sub-lable">
          We texted your code to <span>{state.userData.phone}</span>
        </div>
      );
    }
  };
  const [gender, setGender] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [region, setRegion] = useState("");
  const [residentialAddress, setResidentialAddress] = useState("");
  const [errors, setErrors] = useState({});

  const handleGenderChange = (event) => {
    setGender(event.target.value);
  };
  const handleFirstNameChange = (value) => {
    setFirstName(value);
  };

  const handleLastNameChange = (value) => {
    setLastName(value);
  };

  const handleDateOfBirthChange = (value) => {
    setDateOfBirth(value);
  };

  const handleRegionChange = (value) => {
    setRegion(value);
  };

  const handleResidentialAddressChange = (value) => {
    setResidentialAddress(value);
  };
  const validateFields = () => {
    const newErrors = {};
    if (!firstName) {
      newErrors.firstName = "Firstname is required";
    }
    if (!lastName) {
      newErrors.lastName = "Lastname is required";
    }
    if (!dateOfBirth) {
      newErrors.dateOfBirth = "Date of birth is required";
    }
    // if (!region) {
    //   newErrors.region = 'region is required';
    // }
    if (!residentialAddress) {
      newErrors.region = "Address is required";
    }
    if (!region) {
      newErrors.city = "City is required";
    }
    return newErrors;
  };
  const validateNameFields = () => {
    const newErrors = {};
    if (!firstName) {
      newErrors.firstName = "FirstName is required";
    }
    if (!lastName) {
      newErrors.lastName = "LastName is required";
    }
    return newErrors;
  };
  const handleSubmit = async () => {
    const valErrors = validateFields();
    // console.log(valErrors)
    if (Object.keys(valErrors).length === 0) {
      const formData = new FormData();
      formData.append("user_id", user?.id);
      formData.append("token", state?.token);
      formData.append("gender", gender);
      formData.append("firstName", firstName);
      formData.append("lastName", lastName);
      formData.append("dateOfBirth", dateOfBirth);
      formData.append("region", region);
      formData.append("residentialAddress", residentialAddress);
      setShowNameLoader(true);
      try {
        apiCall(API_URL + "save-details", formData)
          .then((response) => {
            if (response.data.success == "0") {
              localStorage.setItem(
                "userData",
                JSON.stringify(response.data.data)
              );
              localStorage.setItem("token", token);
              dispatch({
                type: "LOGIN",
                payload: { user: response.data.data, token: token },
              });
              setShowNameLoader(false);
              setEmailSuccess(true);
              handleVerificationClose();
            } else {
              console.error("An error occurred");
            }
          })
          .catch((error) => {
            console.error("Error uploading profile picture:", error);
            setShowNameLoader(false);
          });
      } catch (error) {
        console.error("Error uploading files:", error);
        setShowNameLoader(false);
      }
    } else {
      setErrors(valErrors);
    }
  };
  const handleNameChange = async () => {
    const valErrors = validateNameFields();
    if (Object.keys(valErrors).length === 0) {
      const formData = new FormData();
      formData.append("user_id", user?.id);
      formData.append("token", state?.token);
      formData.append("firstName", firstName);
      formData.append("lastName", lastName);
      setShowNameLoader(true);
      try {
        apiCall(API_URL + "save-name", formData)
          .then((response) => {
            if (response.data.success == "0") {
              localStorage.setItem(
                "userData",
                JSON.stringify(response.data.data)
              );
              localStorage.setItem("token", token);
              dispatch({
                type: "LOGIN",
                payload: { user: response.data.data, token: token },
              });
              handleIdentitySteps("identitySteps1");
            } else {
              console.log("error");
            }
            setShowNameLoader(false);
          })
          .catch((error) => {
            setShowNameLoader(false);
            console.error("Error change in name:", error);
          });
      } catch (error) {
        console.error("Error change in name:", error);
        setShowNameLoader(false);
      }
    } else {
      setErrors(valErrors);
    }
  };
  return (
    <>
      <div className="col-12">
        <div className="common-box">
          <div className="common-box-heading">
            <Icons.VerificationSvg />
            Verification Status
          </div>

          {/* verified-step1, verified-step2, verified-step3 */}
          <div className={"verification-status-bx2 " + verifiedStep}>
            <div className="vsb2-data-bx">
              <div className="vdb-icons-bx">
                <div className="vdb-icon-circle">
                  <Icons.VerificationCircleSvg />
                </div>
                <Icons.UserSvg />
              </div>
              <div className="vdb-inner">
                {/* Not verified, Partially verified, Fully verified */}
                <div className={`vsb2-current ${verificationLevel}`}>
                  {verificationLevel}
                </div>
                <div className="vsb2-steps">
                  {completedSteps} / 3 steps complete
                </div>
              </div>
            </div>
            <div className="d-flex flex-wrap gap-2">
              {/* <div className="common-btn-item cbi-fill">
                <span>Complete Now</span>
              </div> */}
              {/* <Link to="/verifications" className="common-btn-item cbi-outline">
                <span>View Details</span>
              </Link> */}
            </div>
          </div>
        </div>
      </div>

      <div className="col-xl-8 col-lg-7">
        <div className="common-box cb-p-bg-none">
          <div className="common-box-heading">Verification steps</div>
          <div
            id="verificationaccordion"
            className="common-accordion vs-accordion mt-0 p-0"
          >
            {/* disabled-step, current-step, pending-step, verified-step, unverified-step */}
            <div
              className={`acc-item ${
                !state.userData.phone_verified ||
                !state.userData.email_verified ||
                !state.userData.profile_verified
                  ? "current-step"
                  : "verified-step"
              }`}
            >
              <div
                className="acc-heding collapsed"
                id={`heading1`}
                data-bs-toggle="collapse"
                data-bs-target={`#collapse1`}
                aria-expanded="true"
                aria-controls={`collapse1`}
              >
                <div className="vs-step-num">1</div>
                <div className="vs-step-check">
                  <Icons.CheckSvg />
                  <Icons.ExclamationTriangleSvg />
                  <Icons.DocumentsCheckSvg />
                </div>
                Personal details confirmed
                <span className="down-icon di-three">
                  <Icons.DownSvg />
                </span>
              </div>

              <div
                id={`collapse1`}
                className="collapse"
                aria-labelledby={`heading1`}
              >
                <div className="vs-accordion-data">
                  <div className="vad-label">Details to confirm</div>
                  {/* <div className="vad-label">Your confirmed details</div> */}
                  
                  <div className="vad-data-list mt-3">
                    <div className="vdl-item">
                      <Icons.DevicePhoneMobileSvg />
                      {state.userData.phone_verified
                        ? state.userData.phone
                        : "verify phone"}
                    </div>
                    <div className="vdl-item">
                      <Icons.UserSvg />
                      {state.userData.profile_verified
                        ? state.userData.name
                        : "verify profile"}
                    </div>
                  </div>

                  <div className="vad-data-list mt-3">
                    <div className="vdl-item">
                      <Icons.AtSymbolSvg />
                      {state.userData.email_verified
                        ? state.userData.email
                        : "verify email"}
                    </div>
                  </div>

                </div>
                <div className="vs-accordion-btns">
                  <div
                    onClick={handleVerificationShow}
                    className="common-btn-item cbi-fill"
                  >
                    <span>Complete Now</span>
                  </div>
                  <div className="input-note-2">
                    Complete step 2 to continue
                  </div>
                </div>
              </div>
            </div>
            {/* disabled-step, current-step, pending-step, verified-step, unverified-step */}
            <div
              className={`acc-item ${
                state.userData.step2_verified 
                  ? "verified-step"
                  : state.userData.step1_verified && !documentVerified
                  ? "current-step"
                  : idStatus === "pending"
                  ? "pending-step"
                  : idStatus === "rejected"
                  ? "unverified-step"
                  : idStatus === "approved"
                  ? "verified-step"
                  : ""
              }`}
            >
              <div
                className="acc-heding collapsed"
                id={`heading2`}
                data-bs-toggle="collapse"
                data-bs-target={`#collapse2`}
                aria-expanded="true"
                aria-controls={`collapse2`}
              >
                <div className="vs-step-num">2</div>
                <div className="vs-step-check">
                  <Icons.CheckSvg />
                  <Icons.ExclamationTriangleSvg />
                  <Icons.DocumentsCheckSvg />
                </div>
                Verify your identity
                <span className="down-icon di-three">
                  <Icons.DownSvg />
                </span>
              </div>

              <div
                id={`collapse2`}
                className="collapse"
                aria-labelledby={`heading2`}
              >
                <div className="vs-accordion-data">
                  {!documentVerified && !idStatus && (
                    <div className="vad-label">
                      {/* Provide a document confirming your name */}
                      Provide a document (ID, Driving License, Passport) confirming your full name and Date of Birth
                    </div>
                  )}
                  {documentVerified && idStatus === "pending" && (
                    <div className="vad-label">Your request is in review</div>
                  )}
                  {documentVerified && idStatus === "approved" && (
                    <>
                      <div className="vad-label">Your verified details</div>
                      <div className="vad-data-list">
                        {state.userData.step2_verified
                          ? state.userData.name
                          : ""}
                      </div>
                    </>
                  )}
                  {!state.userData.step1_verified ? (
                    <div className="input-note-2">
                      Complete step 1 to continue
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="vs-accordion-btns">
                  {state.userData.step1_verified &&
                  (!documentVerified || idStatus === "rejected") ? (
                    <div
                      onClick={handleIdentityShow}
                      className="common-btn-item cbi-fill"
                    >
                      <span>Complete Now</span>
                    </div>
                  ) : (
                    <span>{idStatus}</span> // Display status if the button is not shown
                  )}
                </div>
              </div>
            </div>

            <div
              className={`acc-item ${
                state.userData.step3_verified
                ? "verified-step"
                : state.userData.step1_verified && addressStatus === "rejected"
                ? "unverified-step"
                : state.userData.step1_verified && addressStatus === "pending"
                ? "pending-step"
                : state.userData.step1_verified && addressStatus === "approved"
                ? "verified-step"
                : state.userData.step1_verified
                ? "current-step"
                : ""
              }`}
            >
              <div
                className="acc-heding collapsed"
                id={`heading3`}
                data-bs-toggle="collapse"
                data-bs-target={`#collapse3`}
                aria-expanded="true"
                aria-controls={`collapse3`}
              >
                <div className="vs-step-num">3</div>
                <div className="vs-step-check">
                  <Icons.CheckSvg />
                  <Icons.ExclamationTriangleSvg />
                  <Icons.DocumentsCheckSvg />
                </div>
                Verify residential address
                <span className="down-icon di-three">
                  <Icons.DownSvg />
                </span>
              </div>

              <div
                id={`collapse3`}
                className="collapse"
                aria-labelledby={`heading3`}
              >
                <div className="vs-accordion-data">
                  {!addressVerified && !addressStatus && (
                    <div className="vad-label">
                      {/* You will need to provide proof of your place of residence */}
                      You will need to provide a proof of address (Bank Statement, Utility Bill, Lease Agreement).
                    </div>
                  )}
                  {addressVerified && addressStatus === "pending" && (
                    <div className="vad-label">Your request is in review</div>
                  )}
                  {addressVerified && addressStatus === "approved" && (
                    <>
                      <div className="vad-label">Your verified details</div>
                      <div className="vad-data-list">
                        <div className="vdl-item">
                          {state.userData.step1_verified &&
                          state.userData.residentialAddress ? (
                            <>
                              <Icons.LocationSvg />{" "}
                              {state.userData.residentialAddress}
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </>
                  )}
                </div>
                <div className="vs-accordion-btns">
                  {state.userData.step1_verified && (
                      <Link
                        to="/verifications-form/addressproof"
                        className="common-btn-item cbi-fill"
                      >
                        <span>Complete Now</span>
                      </Link>
                    )}
                  {state.userData.step1_verified && 
                  (!addressVerified || addressStatus === "pending") && (
                        <span>{addressStatus}</span>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-xl-4 col-lg-5">
        <SpecificationAvailability
          setVerificationLabel={setVerificationLabel}
        />
      </div>

      {/* --- Verification steps Modal --- */}
      <Modal
        className="zoom custom-content"
        backdrop="static"
        keyboard={false}
        centered
        show={isVerificationModal}
        onHide={handleVerificationClose}
      >
        <div
          className={`${
            verificationSteps === "verificationSteps1" ? "" : "d-none"
          } `}
          id="verificationSteps1"
        >
          <div className="custom-modal-header">
            <div className="cmh-lable">Verification steps</div>
            <div className="cmh-sub-lable">This will take 2-5 minutes</div>

            {/* <span className="close-icon" onClick={handleVerificationClose}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                        <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M368 368L144 144M368 144L144 368"></path>
                    </svg>
                </span> */}
          </div>

          <div className="custom-modal-body">
            <div className="px-3 px-sm-4 pb-2">
              <div className="row row-gap-2">
                <div className="col-12">
                  <div
                    className={`verification-item ${
                      state.userData.email_verified ? "vi-verified" : ""
                    }`}
                  >
                    <Icons.AtSymbolSvg />
                    <div className="vi-data">
                      <div className="vi-lable">1. Confirm email address</div>
                      <div className="vi-data">
                        {state.userData.email_verified
                          ? state.userData.email
                          : "Verify Email"}
                      </div>
                    </div>
                    {state.userData.email_verified && <Icons.CheckCircleSvg />}
                  </div>
                </div>

                <div className="col-12">
                  <div
                    className={`verification-item ${
                      state.userData.phone_verified ? "vi-verified" : ""
                    }`}
                  >
                    <Icons.DevicePhoneMobileSvg />
                    <div className="vi-data">
                      <div className="vi-lable">2. Confirm phone number</div>
                      <div className="vi-data">
                        {state.userData.phone_verified
                          ? state.userData.phone
                          : "Make your account more secure"}
                      </div>
                    </div>
                    {state.userData.phone_verified && <Icons.CheckCircleSvg />}
                  </div>
                </div>

                <div className="col-12">
                  <div
                    className={`verification-item ${
                      state.userData.profile_verified ? "vi-verified" : ""
                    }`}
                  >
                    <Icons.UserSvg />
                    <div className="vi-data">
                      <div className="vi-lable">3. Add profile information</div>
                      <div className="vi-data">
                        {state.userData.profile_verified
                          ? state.userData.name
                          : "Get a more tailored experience"}
                      </div>
                    </div>
                    {state.userData.profile_verified && (
                      <Icons.CheckCircleSvg />
                    )}
                  </div>
                </div>

                <div className="col-12 mt-2 mb-3">
                  <div className="d-flex justify-content-end flex-wrap gap-2 mb-4">
                    <div
                      className="common-btn-item cbi-outline"
                      onClick={handleVerificationClose}
                    >
                      <span>Do it later</span>
                    </div>
                    {/* <div className="common-btn-item cbi-fill"><span>Get started now</span></div> */}
                    <div
                      onClick={() => {
                        if (
                          !state.userData.email_verified ||
                          !state.userData.phone_verified
                        ) {
                          handleVerificationSteps("verificationSteps2");
                          handalSendOtp();
                        } else if (!state.userData.profile_verified) {
                          handleVerificationSteps("verificationSteps3");
                        }
                      }}
                      className="common-btn-item cbi-fill"
                    >
                      <span>Continue</span>
                    </div>
                  </div>
                  {/* <div className="input-note-2 text-center"><Icons.LockClosedSvg /> All data is encrypted for security</div> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={`${ verificationSteps === "peFormStep" ? "" : "d-none"} `} id="peFormStep">
            <div className="custom-modal-header">
              <div className="cmh-lable">Update your phone number</div>
              <div className="cmh-sub-lable">Please enter your phone number carefully, because on the next screen you will be asked for the OTP.</div>
              <div
                onClick={() => handleVerificationSteps("verificationSteps1")}
                className={`CommonBackBtn`}
              >
                <Icons.LeftArrowSvg />
              </div>
              <span className="close-icon" onClick={handleVerificationClose}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <path
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="32"
                    d="M368 368L144 144M368 144L144 368"
                  ></path>
                </svg>
              </span>
            </div>

            <div className="px-3 px-sm-4 pb-2">
              <div className="row signinup-group-style sg-fem-style">
                <div className="col-12">
                  <Input
                    className={errors.firstName ? "error-group" : ""}
                    heading="Email ID"
                    type="mail"
                    placeholder="Enter email id"
                    onChange={handleFirstNameChange}
                  />
                  {errors.firstName && (
                    <span className="form-error-msg">{errors.firstName}</span>
                  )}
                </div>

                <div className="col-12">
                  <Input
                    className={errors.firstName ? "error-group" : ""}
                    heading="Phone number"
                    type="number"
                    placeholder="Enter phone number"
                    onChange={handleFirstNameChange}
                  />
                  {errors.firstName && (
                    <span className="form-error-msg">{errors.firstName}</span>
                  )}
                </div>

                <div className="col-12">
                  {!showNameLoader && (
                    <div className="common-submit-btn">Continue</div>
                  )}
                  {showNameLoader && (
                    <div className="lsb-loader-btn"><img src={Img.loading} alt="" />Please wait ....</div>
                  )}
                </div>
              </div>
            </div>
        </div>

        <div
          className={`${
            verificationSteps === "verificationSteps2" ? "" : "d-none"
          } `}
          id="verificationSteps2"
        >

          <div className="custom-modal-header">
            <div className="cmh-lable">Enter 4-digit code</div>
            {getSubLabel()}
            <div
              onClick={() => handleVerificationSteps("verificationSteps1")}
              className={`CommonBackBtn`}
            >
              <Icons.LeftArrowSvg />
            </div>
            <span className="close-icon" onClick={handleVerificationClose}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="32"
                  d="M368 368L144 144M368 144L144 368"
                ></path>
              </svg>
            </span>
          </div>

          <div className="">
            <div className="px-3 px-sm-4 pb-2">
              <div className="row signinup-group-style">
                <div className="col-12">
                  <div className="signinup-group otp-group">
                    {otp.map((digit, index) => (
                      <>
                        <input
                          key={index}
                          inputMode="numeric"
                          type="text"
                          maxLength="1"
                          placeholder=" "
                          value={digit}
                          onChange={(e) => handleChange(e, index)}
                          onKeyDown={(e) => handleKeyDown(e, index)}
                          ref={inputRefs[index]}
                        />
                      </>
                    ))}
                  </div>
                  {otpErrors.otpError && (
                    <span className="form-error-msg">{otpErrors.otpError}</span>
                  )}
                </div>

                <div className="col-12">
                  <div className="otp-resend-bx">
                    <div>Have Not Received Code</div>
                    {(!showOtpLoader && !showLoader) && (
                    timer > 0 ? (
                      <div>
                        Resend In <span>{timer}s</span>
                      </div>
                    ) : (
                      <div>
                        <div className="reotp-btn" onClick={handalReSendOtp}>
                          Resend
                        </div>
                      </div>
                    ))}
                    {/* <div><div className="reotp-btn">Resend</div></div> */}
                  </div>
                </div>
                <div className="col-12">
                  {(!showOtpLoader && !showLoader) && (
                    <div
                      className="common-submit-btn"
                      onClick={() => handalVerifyOtp()}
                    >
                      Continue
                    </div>
                  )}

                  {(showOtpLoader || showLoader) && (
                    <div className="lsb-loader-btn">
                      <img src={Img.loading} alt="" />
                      Please wait ....
                    </div>
                  )}
                  
                </div>
              </div>
            </div>
          </div>

        </div>

        <div
          className={`${
            verificationSteps === "verificationSteps3" ? "" : "d-none"
          } `}
          id="verificationSteps3"
        >
          <div className="custom-modal-header">
            <div className="cmh-lable">Add profile information</div>

            <div
              onClick={() => handleVerificationSteps("verificationSteps1")}
              className={`CommonBackBtn`}
            >
              <Icons.LeftArrowSvg />
            </div>

            <span className="close-icon" onClick={handleVerificationClose}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="32"
                  d="M368 368L144 144M368 144L144 368"
                ></path>
              </svg>
            </span>
          </div>

          <div className="">
            <div className="px-3 px-sm-4 pb-2">
              <div className="row signinup-group-style sg-fem-style">
                <div className="col-12">
                  <div className="sgr-outer-label">Your gender</div>
                  <div className="d-flex flex-wrap column-gap-3 row-gap-3  mt-2 mb-4">
                    <RadioInput
                      className="mb-1 sgc-radio"
                      name="gender"
                      id="male"
                      label="Male"
                      value="male"
                      checked={gender === "male"}
                      onChange={handleGenderChange}
                    />
                    <RadioInput
                      className="mb-1 sgc-radio"
                      name="gender"
                      id="female"
                      label="Female"
                      value="female"
                      checked={gender === "female"}
                      onChange={handleGenderChange}
                    />
                    <RadioInput
                      className="mb-1 sgc-radio"
                      name="gender"
                      id="other"
                      label="Other"
                      value="other"
                      checked={gender === "other"}
                      onChange={handleGenderChange}
                    />
                    {errors.gender && (
                      <span className="form-error-msg">{errors.gender}</span>
                    )}
                  </div>
                </div>

                <div className="col-6">
                  <Input
                    className={errors.firstName ? "error-group" : "" }
                    heading="First Name"
                    type="text"
                    placeholder="Enter first name"
                    onChange={handleFirstNameChange}
                    value={firstName}
                  />
                  {errors.firstName && (
                    <span className="form-error-msg">{errors.firstName}</span>
                  )}
                </div>

                <div className="col-6">
                  <Input
                    className={errors.lastName ? "error-group" : "" }
                    heading="Last Name"
                    type="text"
                    placeholder="Enter last name"
                    onChange={handleLastNameChange}
                    value={lastName}
                  />
                  {errors.lastName && (
                    <span className="form-error-msg">{errors.lastName}</span>
                  )}
                </div>

                <div className="col-sm-6">
                  <div className="sgr-outer-label">Date of Birth</div>
                  <DatePickerComponent
                    className={errors.dateOfBirth ? "error-group" : ""}
                    label="Select date"
                    desktopModeMediaQuery="@media (max-width: 0px)"
                    onChange={handleDateOfBirthChange}
                    value={dateOfBirth}
                  />
                  {errors.dateOfBirth && (
                    <span className="form-error-msg mt-2">
                      {errors.dateOfBirth}
                    </span>
                  )}
                </div>

                <div className="col-sm-6">
                  {/* <SelectDropItem
                    className=""
                    label="Your region"
                    heading="Select region"
                    options={regionOption}
                    onChange={handleRegionChange}
                  /> */}
                  <Input
                    className={errors.city ? "error-group" : "" }
                    heading="City"
                    type="text"
                    placeholder="Enter city"
                    onChange={handleRegionChange}
                    value={region}
                  />
                  {/* {errors.region && <span className="form-error-msg">{errors.region}</span>} */}
                  {errors.city && (
                    <span className="form-error-msg">{errors.city}</span>
                  )}
                </div>

                <div className="col-12">
                  <Textarea
                    className={errors.region ? "error-group" : "" }
                    heading="Your residential address"
                    name="residential"
                    placeholder="Street, House (apartment)"
                    onChange={handleResidentialAddressChange}
                  />
                  {errors.region && (
                    <span className="form-error-msg">{errors.region}</span>
                  )}
                </div>

                <div className="col-12">
                  {!showNameLoader && (
                    <div className="common-submit-btn" onClick={handleSubmit}>
                      Continue
                    </div>
                  )}
                  {showNameLoader && (
                    <div className="lsb-loader-btn">
                      <img src={Img.loading} alt="" />
                      Please wait ....
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      {/* --- Identity steps Modal --- */}
      <Modal
        className="zoom custom-content"
        keyboard={false}
        centered
        show={isIdentityModal}
        onHide={handleIdentityClose}
      >
        <div
          className={`${identitySteps === "identitySteps1" ? "" : "d-none"} `}
          id="identitySteps1"
        >
          <div className="custom-modal-header">
            <div className="cmh-lable">Document verification</div>
            <div className="cmh-sub-lable">
              On the next screen we will ask you to upload one or two documents
              that can prove your name and country / region of residence. Your
              current place of residence is India
            </div>

            <span className="close-icon" onClick={handleIdentityClose}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="32"
                  d="M368 368L144 144M368 144L144 368"
                ></path>
              </svg>
            </span>
          </div>

          <div className="custom-modal-body">
            <div className="px-3 px-sm-4 pb-2">
              <div className="row row-gap-2 mt-3">
                <div className="col-12">
                  <div className="signinup-group-detail-item sgdi-bottom-line">
                    <div className="sgdi-lable">Check your name</div>
                    <div className="sgdi-data">{state.userData.name}</div>
                    <div
                      onClick={() => handleIdentitySteps("identitySteps2")}
                      className="sgdi-btn common-btn-item cbi-fill cbi-small"
                    >
                      <span>
                        <Icons.EditSvg />
                        Edit
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-12">
                  {state.userData.step1_verified && (
                    <Link
                      to="/verifications-form/idproof"
                      className="common-submit-btn"
                    >
                      Upload documents
                    </Link>
                  )}

                  {/* <div className="lsb-loader-btn">
                                <img src={Img.loading} alt="" />Please wait ....
                            </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className={`${identitySteps === "identitySteps2" ? "" : "d-none"} `}
          id="identitySteps2"
        >
          <div className="custom-modal-header">
            <div className="cmh-lable">Update your name</div>

            <div
              onClick={() => handleIdentitySteps("identitySteps1")}
              className={`CommonBackBtn`}
            >
              <Icons.LeftArrowSvg />
            </div>

            <span className="close-icon" onClick={handleIdentityClose}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="32"
                  d="M368 368L144 144M368 144L144 368"
                ></path>
              </svg>
            </span>
          </div>

          <div className="">
            <div className="px-3 px-sm-4 pb-2">
              <div className="row signinup-group-style sg-fem-style">
                <div className="col-6">
                  <Input
                    className={errors.firstName ? "error-group" : ""}
                    heading="First Name"
                    type="text"
                    placeholder="Enter first name"
                    onChange={handleFirstNameChange}
                  />
                  {errors.firstName && (
                    <span className="form-error-msg">{errors.firstName}</span>
                  )}
                </div>

                <div className="col-6">
                  <Input
                    className={errors.lastName ? "error-group" : ""}
                    heading="Last Name"
                    type="text"
                    placeholder="Enter last name"
                    onChange={handleLastNameChange}
                  />
                  {errors.lastName && (
                    <span className="form-error-msg">{errors.lastName}</span>
                  )}
                </div>

                <div className="col-12">
                  {!showNameLoader && (
                    <div
                      className="common-submit-btn"
                      onClick={handleNameChange}
                    >
                      Continue
                    </div>
                  )}
                  {showNameLoader && (
                    <div className="lsb-loader-btn">
                      <img src={Img.loading} alt="" />
                      Please wait ....
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      {/* --- Message Modal --- */}
      <Modal
        className="zoom custom-content"
        keyboard={false}
        centered
        show={isMessageModal}
        onHide={handleMessageClose}
      >
        <div className="custom-modal-header">
          <div className="cmh-icon">
            <Icons.CheckCircleSvg />
          </div>
          <div className="cmh-lable">Contact details verified</div>
          <div className="cmh-sub-lable">
            The next step is verifying your identity. please prepare your
            documents.
          </div>

          <span className="close-icon" onClick={handleMessageClose}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <path
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="32"
                d="M368 368L144 144M368 144L144 368"
              ></path>
            </svg>
          </span>
        </div>

        <div className="custom-modal-body">
          <div className="px-3 px-sm-4 pb-2">
            <div className="row row-gap-2">
              <div className="col-12">
                <div className="d-flex justify-content-center flex-wrap gap-2 mb-3">
                  <div className="common-btn-item cbi-fill">
                    <span>Verify identity now</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      {otpSuccess && (
        <FlashMessage
          type="success"
          isvisible={true}
          message="Otp verified successfully."
        />
      )}
      {otpSuccsess && (
        <FlashMessage
          type="success"
          isvisible={true}
          message="Otp sent successfully."
        />
      )}
      {otpError && (
        <FlashMessage
          type="error"
          isvisible={true}
          message="Invalid otp or expired"
        />
      )}
      {emailSuccess && (
        <FlashMessage
          type="success"
          isvisible={true}
          message="Details verified successfully."
        />
      )}
    </>
  );
}
