import React ,{ useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom';
import { useAuth, getLoggedInUserData } from '../../context/AuthContext';

// Lightgellery files
import LightGallery from 'lightgallery/react';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';
import API_URL from '../../config/config';
import useApi from '../../utility/apiCall';


// import styles
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';

// Icon Files
import Icons from '../../components/icons';
import * as Img from '../../components/Img'

// Design File
import EmptyBox from '../../components/EmptyBox';


// Demo Img
import lghBg2 from "../../img/bg/lghBg2.webp"

export default function DocumentVerification() {
  const { apiCall } = useApi();
  const openGalleryPopRef = useRef(null);
  const [user, setUser] = useState('')
  const { state } = useAuth();
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const [verifiedDocuments, setverifiedDocuments] = useState([]);
  const [currentDocument, setCurrentDocument] = useState(null);
  const isInitialMount = useRef(true);
  
  useEffect(() => {
    if (isInitialMount.current) {
        isInitialMount.current = false;
        const loggedInUser = getLoggedInUserData();
        const isUserLoggedIn = !!loggedInUser;
        if (isUserLoggedIn) {
            setUserLoggedIn(true);
            setUser(loggedInUser);
            
        }
        fetchVerifiedDocuments();
    }
  }, [state.userData]);

  const fetchVerifiedDocuments = async () => {
    try {
        const response = await apiCall(API_URL + 'get-verified-documents', {
          user_id:state?.userData?.id,
          token:state?.token,
        });
        if (response.data.success == '0') {
            setverifiedDocuments(response.data.data)
        }
    } catch (error) {
        console.error('Error fetching data:', error);
    }

  };
  const setDocuments = (index) =>{
    // console.log(index[0]);
    setCurrentDocument(index);
    if (openGalleryPopRef.current) {
        // Trigger click event on the button
        openGalleryPopRef.current.click();
    }
    
  }

  const groupedDocuments = verifiedDocuments.reduce((acc, document) => {
    const key = `${document.document_type_id}-${document.application_id}`;
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(document);
    return acc;
  }, {});

  return (
    <>
    { verifiedDocuments.length > 0 &&
    <div className="common-box">
        <div className="common-box-heading mb-2"><Icons.DocumentsSvg />Document Verification</div>

        <div className="row">

            {/* { verifiedDocuments.length > 0 && */}
                <div className="col-12">
                    <div className="document-verification-bx">

                        <div className="table-responsive">
                            <table className="custom-table">
                                <thead>
                                    <tr>
                                        <th>Document Group</th>
                                        <th>Document Type</th>
                                        <th>Date Created</th>
                                        <th>Date Processed</th>
                                        <th>Status</th>
                                        <th>View</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {Object.keys(groupedDocuments).map((key)=> {
                                        const documents = groupedDocuments[key];
                                        const document = documents[0]; // Use the first document to display group info

                                        return (
                                            <tr key={key}>
                                                <td>{document.document_type.caption}</td>
                                                <td>{document.document_type.caption}</td>
                                                <td>{document.updated_at_formattad}</td>
                                                <td>{document.created_at_formattad}</td>
                                                <td>
                                                    {document.status === 'pending' && (
                                                        <div className="td-status td-pending">
                                                            <Icons.DotSvg />
                                                            Processing
                                                        </div>
                                                    )}
                                                    {document.status === 'approved' && (
                                                        <div className="td-status td-approved">
                                                            <Icons.CheckSvg />
                                                            Verified
                                                        </div>
                                                    )}
                                                    {document.status === 'rejected' && (
                                                        <div className="td-status td-rejected">
                                                            <Icons.XMarkSvg />
                                                            Rejected
                                                        </div>
                                                    )}
                                                </td>
                                                <td>
                                                    <div className="td-view" onClick={() => setDocuments(documents)}>
                                                        <Icons.PhotoSvg />
                                                        View
                                                    </div>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>


                        {/* --- Headinh bx start --- */}
                        {/* 
                        <div className="dvb-list-heading-bx">
                            <div className="dlhb-item">Document Group</div>
                            <div className="dlhb-item">Document Type</div>
                            <div className="dlhb-item">Date Created</div>
                            <div className="dlhb-item">Date Processed</div>
                            <div className="dlhb-item">Status</div>
                            <div className="dlhb-item"></div>
                        </div> 
                        */}
                        {/* --- Headinh bx end --- */}

                        {/* { verifiedDocuments.map((document, index) => (
                        <div className="dvb-list-data-bx">
                            <div className="dldb-item">
                                <div className="dldb-lable">Document Group</div>
                                <div className="dldb-data">{document.document_type.document_group.caption}</div>
                            </div>
                            <div className="dldb-item">
                                <div className="dldb-lable">Document Type</div>
                                <div className="dldb-data">{document.document_type.caption}</div>
                            </div>
                            <div className="dldb-item">
                                <div className="dldb-lable">Date Created</div>
                                <div className="dldb-data">{document.process_date}</div>
                            </div>
                            <div className="dldb-item">
                                <div className="dldb-lable">Date Processed</div>
                                <div className="dldb-data">{document.date_of_create}</div>
                            </div>
                            { document.status == 'pending' && 
                                <div className="dldb-item">
                                    <div className="dldb-lable">Status</div>
                                    <div className="dldb-data color-pending"><Icons.DotSvg />Processing</div>
                                </div>
                            }
                            { document.status == 'approved' && 
                                <div className="dldb-item">
                                    <div className="dldb-lable">Status</div>
                                    <div className="dldb-data color-success"><Icons.CheckSvg />Verified</div>
                                </div>
                            }
                            { document.status == 'rejected' && 
                                <div className="dldb-item">
                                <div className="dldb-lable">Status</div>
                                <div className="dldb-data color-error"><Icons.XMarkSvg />Rejected</div>
                                </div>
                            }
                        
                            <div className="dldb-item d-none" data-bs-toggle="modal" data-bs-target="#ViewDocumentListModal">
                                <div className="dldb-data dldb-view"><Icons.PhotoSvg /> View</div>
                            </div>

                            <div className="dldb-item" >
                                <div className="dldb-data dldb-view" onClick={() => setDocuments(index)}><Icons.PhotoSvg /> View</div>
                            </div>
                        </div>
                        )) } */}

                        {/* 
                        <div className="dvb-list-data-bx">
                            <div className="dldb-item">
                                <div className="dldb-lable">Document Group</div>
                                <div className="dldb-data">Id proof</div>
                            </div>
                            <div className="dldb-item">
                                <div className="dldb-lable">Document Type</div>
                                <div className="dldb-data">Driving License</div>
                            </div>
                            <div className="dldb-item">
                                <div className="dldb-lable">Date Created</div>
                                <div className="dldb-data">15.04.2024</div>
                            </div>
                            <div className="dldb-item">
                                <div className="dldb-lable">Date Processed</div>
                                <div className="dldb-data">-</div>
                            </div>
                            <div className="dldb-item">
                                <div className="dldb-lable">Status</div>
                                <div className="dldb-data color-pending"><Icons.DotSvg />Processing</div>
                            </div>
                            <div className="dldb-item">
                                <div className="dldb-lable">Status</div>
                                <div className="dldb-data color-error"><Icons.XMarkSvg />Rejected</div>
                            </div>
                             <div className="dldb-item">
                                <div className="dldb-lable">Status</div>
                                <div className="dldb-data color-success"><Icons.CheckSvg />Verified</div>
                            </div>
                            <div className="dldb-item" data-bs-toggle="modal" data-bs-target="#ViewDocumentListModal">
                                <div className="dldb-data dldb-view"><Icons.PhotoSvg /> View</div>
                            </div>
                        </div> 
                        */}

                    </div>
                </div>
            {/* } */}

            { verifiedDocuments.length == 0 &&
                <EmptyBox 
                    className="empty-div-bx" 
                    eh="Document not found" 
                    esh="Data is not available"
                    // buttonLink = "/verifications" 
                    // buttonText = "Click To Upgrade" 
                />
            }    
            
        </div>
    </div>
    }

    <div data-bs-toggle="modal" data-bs-target="#ViewDocumentListModal" style={{display:"none"}} ref={openGalleryPopRef}>
    </div>

    {/* --- Change Password Modal Popup --- */}
    <div className="modal fade zoom" id="ViewDocumentListModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content custom-content mx-auto">

                <div className="custom-modal-header">
                    <div className="cmh-lable"> {
                            currentDocument && currentDocument[0].document_type.document_group.caption}</div>

                    <span className="close-icon" data-bs-dismiss="modal" aria-label="Close">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                            <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M368 368L144 144M368 144L144 368"></path>
                        </svg>
                    </span>
                </div>

                <div className="custom-modal-data px-3 pb-3">

                <LightGallery plugins={[lgZoom, lgThumbnail]} className="row">
                    {currentDocument && currentDocument.map((doc, index) => (
                        doc.file_ext !== 'pdf' && (
                            <Link to={doc.document_url} key={index} className="d-block col-12 mb-3" data-bs-dismiss="modal">
                                <div className="upload-document-item">
                                    <div className="udi-img-bx">
                                        <img src={doc.document_url} alt={doc.document_type.caption} />
                                    </div>
                                    <div className="udi-data-bx">
                                        <div className="udi-data-name">{doc.document_type.caption}</div>
                                        <div className="udi-data-type-size">{doc.file_ext} {doc.file_size}</div>
                                    </div>
                                    <div className="udi-icon-bx">
                                        <Icons.ZoomSvg />
                                    </div>
                                </div>
                            </Link>
                        )
                    ))}
                </LightGallery>
                {currentDocument && currentDocument.map((doc, index) => (
                    doc.file_ext === 'pdf' && (
                        <div className="row" key={index}>
                            <a href={doc.document_url} target="_blank" rel="noopener noreferrer" className="d-block col-12 mb-3">
                                <div className="upload-document-item">
                                    <div className="udi-img-bx">
                                        <img src={Img.pdf} alt="PDF Icon" />
                                    </div>
                                    <div className="udi-data-bx">
                                        <div className="udi-data-name">{doc.document_type.caption}</div>
                                        <div className="udi-data-type-size">PDF {doc.file_size}</div>
                                    </div>
                                    <div className="udi-icon-bx">
                                        <Icons.ViewPassSvg />
                                    </div>
                                </div>
                            </a>
                        </div>
                    )
                ))}
                    {/* <div className="row">
                        <a href="/" target='_blank' className="d-block col-12 mb-3">
                            <div className="upload-document-item">
                                <div className="udi-img-bx">
                                    <img src={Img.pdf} alt="" />
                                </div>
                                <div className="udi-data-bx">
                                    <div className="udi-data-name">lorem lorem lorem lorem lorem lorem lorem lorem</div>
                                    <div className="udi-data-type-size">PDF, 57.2 Kb</div>
                                </div>
                                <div className="udi-icon-bx">
                                    <Icons.ViewPassSvg />
                                </div>
                            </div>
                        </a>
                    </div> */}
                    

                    <div className="document-reject-reason-bx">
                        {/* <div className="drrb-lable"><Icons.InfoSvg />Reason for rejection</div> */}
                        {
                            currentDocument && 
                            <div className="drrb-text mt-0">Document {currentDocument.status}</div>
                        }
                    </div>

                </div>

            </div>
        </div>
    </div>
    </>
  )
}
