import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import useApi from '../../utility/apiCall';
import API_URL from '../../config/config';
import FlashMessage from '../../components/FlashMessage';

// Design Files
import { ThemeMode } from '../../context/ThemeMode';
import PageHeader from './PageHeader';
import PageBackBtn from './PageBackBtn';

// Images Common File
import * as Img from '../../components/Img';

export default function PageRestore() {
  const { apiCall } = useApi();
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [verificationSuccsess, setVerificationSuccsess] = useState(false);
  const [verificationError, setVerificationError] = useState(false);
  const navigate = useNavigate();

  const [isLoading,setIsLoading] = useState(false);


  const fetchData = async () => {
    setVerificationSuccsess(false);
    setVerificationError(false);
    if (email) {
      setIsLoading(true);
      try {
        const response = await apiCall(API_URL +'send-reset-otp', {
          email: email,
        });
        if (response.data.success == '0') {
          setVerificationSuccsess(true);
          setTimeout(() => {
            navigate('/restoreotp', { state: {userId: response.data.data.id, userEmail: response.data.data.email,resend_type:"reset_password" } });
          }, 2000);
        } else if (response.data.success == '2'){
          setVerificationError(true);
        }
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    } else {
      if (!email) {
        setEmailError(true)
      }
    }

  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent default form submission
      fetchData(); // Call the sign-in function
    }
  };
  // --- ---
  const { isDarkMode } = ThemeMode();
  // --- ---

  return (
    <>
      <Helmet>
        <title>Restore a password | Winpro Fx</title>
      </Helmet>

      <PageHeader />
      <PageBackBtn />

      <section className="lgh-wraper">
        <div className="container-lg cl-custome">
          <div className={`signinup-screen ${isDarkMode ? " " : "signinup-group-style"}`}>

            <div className="signinup-header">
              <div className="screen-hed">Password Recovery</div>
              <div className="screen-pera">Enter your email to recover your password</div>
            </div>

            <form className="signinup-width" onKeyPress={handleKeyPress}>
              <div className="row">

                <div className="col-12">
                  {/* error-group */}
                  <div className={`signinup-group ${emailError ? 'error-group' : ''}`}>
                    <input type="text" value={email} onChange={(e) => { setEmailError(false); setEmail(e.target.value); }} placeholder="Enter your email" />
                    {/* <div className="group__label">Email</div> */}
                    {/* <div className="group__line"></div> */}
                    {emailError && <span className="form-error-msg">Email is required</span>}
                  </div>
                </div>

                <div className="col-12">
                  {/* to="/resetpassword" */}
                  {!isLoading && <div onClick={fetchData} className="common-submit-btn">Reset Password</div>}
                  {/* <button type="button" className="common-submit-btn">Reset Password</button> */}

                  {isLoading && <div className="lsb-loader-btn">
                      <img src={Img.loading} alt="" />Please wait ....
                  </div>}
                </div>

                {verificationSuccsess && <FlashMessage type="success" isvisible={true} message="An otp has been sent to your registerd email" />}
                {verificationError && <FlashMessage type="error" isvisible={true} message="No user found." />}

                <div className="col-12 BackBtnIsShow">
                  <div className="signinup-link-outer-bx slob-fdc fdc-two mb-0">
                    Back to
                    <Link to="/" className="signinup-link-two">Sign in</Link>
                  </div>
                </div>

              </div>
            </form>

          </div>
        </div>
      </section>


    </>
  );
};
