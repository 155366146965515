import React, { useState } from 'react';
import Breadcrumb from '../../components/Breadcrumb';
import Input from '../../components/CommonInput/Input';
import Textarea from '../../components/CommonInput/Textarea';
import FileUpload from '../../components/CommonInput/FileUpload';
import FlashMessage from '../../components/FlashMessage';
import * as Img from '../../components/Img';
import API_URL from '../../config/config';
import { useAuth } from '../../context/AuthContext'; // Import useAuth

export default function PageSuggest() {
    const [title, setTitle] = useState('');
    const [detail, setDetail] = useState('');
    const [file, setFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [errors, setErrors] = useState({
        title: '',
        detail: '',
    });
    const [isSubmitting, setIsSubmitting] = useState(false);

    // Use useAuth to get userId
    const { state: { userData } } = useAuth();
    const userId = userData ? userData.id : null; // Assuming userData has an id field

    const handleFileChange = (selectedFiles) => {
        if (selectedFiles.length > 0) {
            setFile(selectedFiles[0]); // Assuming only one file
        } else {
            setFile(null);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

    
        setErrors({
            title: '',
            detail: '',
        });
        setSuccess('');
        setError('');

    
        const newErrors = {};
        if (!title.trim()) newErrors.title = 'Title is required.';
        if (!detail.trim()) newErrors.detail = 'Detail is required.';

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return; // If there are errors, do not proceed to the API call
        }
         if (isSubmitting) return; // Check if submission is already in progress
        setIsSubmitting(true); 

        // If no errors, proceed with loading and API call
        setLoading(true);

        const formData = new FormData();
        formData.append('title', title);
        formData.append('detail', detail);
        if (file) formData.append('screenshot', file);
        if (userId) formData.append('user_id', userId); // Add user_id to form data

        try {
            const response = await fetch(API_URL + 'suggest-feature', {
                method: 'POST',
                body: formData,
                headers: {
                    'Accept': 'application/json',
                },
            });

            if (!response.ok) {
                const errorData = await response.json();
                if (response.status === 422) {
                    setErrors({
                        title: errorData.errors.title ? errorData.errors.title.join(', ') : '',
                        detail: errorData.errors.detail ? errorData.errors.detail.join(', ') : '',
                    });
                } else {
                    throw new Error('Something went wrong');
                }
            } else {
                const result = await response.json();
                setSuccess('Suggestion sent successfully!');
                setTitle('');
                setDetail('');
                setFile(null);
            }
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
            setIsSubmitting(false);
        }
    };
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleSubmit(e); 
        }
    };


    return (
        <>
            <Breadcrumb breadcrumbIcon="ChatBubbleOvalLeftEllipsisSvg" breadcrumbHeading="Suggest a feature" />
            <div className="container-lg pt-3 pb-5 px-lg-4">
                <div className="row row-gap-4">
                    <form className="col-xl-8 col-12 mx-auto" onSubmit={handleSubmit} onKeyDown={handleKeyDown}>
                        <div className="common-box">
                            <div className="row signinup-group-style sg-fem-style">
                                <div className="col-12">
                                    <Input
                                        className={errors.title ? "error-group" : ""}
                                        heading="Suggest title"
                                        type="text"
                                        placeholder="Briefly describe your idea."
                                        value={title}
                                        onChange={(newValue) => setTitle(newValue)}
                                    />
                                    {errors.title && <div className="form-error-msg">{errors.title}</div>}
                                </div>
                                <div className="col-12">
                                    <Textarea
                                        className={errors.detail ? "error-group" : ""}
                                        heading="Suggest detail"
                                        placeholder="Describe your idea…"
                                        value={detail}
                                        onChange={(newValue) => setDetail(newValue)}
                                    />
                                    {errors.detail && <div className="form-error-msg">{errors.detail}</div>}
                                </div>
                                <div className="col-12">
                                    <div className="sgr-outer-label mb-2">Suggest Screenshot</div>
                                    <FileUpload
                                        id="file-upload"
                                        acceptedFileTypes="image/*"
                                        maxFiles={1}
                                        maxFileSize={5}
                                        onChange={handleFileChange} // Ensure this function is correctly handled in FileUpload
                                        errors={false}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="mt-4">
                            <button type="submit" className={`common-submit-btn ${loading ? 'lsb-loader-btn' : ''}`}>
                                {loading ? (
                                    <>
                                        <img src={Img.loading} alt="" /> Please wait ....
                                    </>
                                ) : (
                                    'Send a suggestion'
                                )}
                            </button>
                            {error && <FlashMessage type="error" isvisible={true} message={error} />}
                            {success && <FlashMessage type="success" isvisible={true} message={success} />}
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}
