import React from 'react'

// Design Fils
// import CommonLayout from '../PageCommonLayout/CommonLayout'
import Breadcrumb from '../../components/Breadcrumb';
import WalletBalances from './WalletBalances';
// import Wallets from './Wallets';

export default function PageWallet() {

  return (
    <>

        <Breadcrumb breadcrumbIcon="EwalletsSvg" breadcrumbHeading="Balance" />

        <div className="container-lg pt-3 pb-5 px-lg-4">

          <div className="row row-gap-4">

            <div className="col-12">
              <WalletBalances />
            </div>

            {/* <div className="col-12 mt-3">
              <Wallets />
            </div> */}
            
          </div>

        </div>
    </>
  )
}
